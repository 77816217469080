@text: #595959;
@text-grey: #8c8c8c;
@text-light: #999;
@placeholder-color: #999;
@border: #ededed;

@primary: #22b8ff;
@strong: #46cadb;

@danger: #ff3b30;
@warn: #faad14;

@barDark: #46cadb;

@level0: #f8be1c;
@level1: #f69623;
@level2: #f56e23;
@level3: #f53d23;

// 表单控件默认高度（带边框）
@fc-height: 32px;

.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
