.exception-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #fff;
}
.exception-page .ep-body {
  text-align: center;
}
.exception-page img {
  width: 258px;
  height: 132px;
}
.exception-page h3 {
  margin-top: 10px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
}
.exception-page h3 p {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}
