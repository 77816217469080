@charset "utf8";
/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(./font/sTdaA6j0Psb920Vjv-mrzH-_kf6ByYO6CLYdB4HQE-Y.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
}
/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(./font/uYECMKoHcO9x1wdmbyHIm3-_kf6ByYO6CLYdB4HQE-Y.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(./font/tnj4SB6DNbdaQnsM8CFqBX-_kf6ByYO6CLYdB4HQE-Y.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(./font/_VYFx-s824kXq_Ul2BHqYH-_kf6ByYO6CLYdB4HQE-Y.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(./font/NJ4vxlgWwWbEsv18dAhqnn-_kf6ByYO6CLYdB4HQE-Y.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(./font/Ks_cVxiCiwUWVsFWFA3Bjn-_kf6ByYO6CLYdB4HQE-Y.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(./font/oMMgfZMQthOryQo9n22dcuvvDin1pK8aKteLpeZ5c0A.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
.tabs {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 60px;
}
.tabs .tab-item {
  margin: 0 20px;
  font-size: 16px;
  line-height: 58px;
  border-bottom: 2px solid transparent;
}
.tabs .tab-item.active {
  color: #22b8ff;
  border-color: #22b8ff;
}
.avatar {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #fff none no-repeat center / cover;
  vertical-align: middle;
  border-radius: 50%;
}
.avatar.show-title::before {
  display: none;
  content: attr(data-title);
  position: absolute;
  bottom: 100%;
  top: auto;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -6px);
  padding: 4px 8px;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 3px;
  background-color: rgba(59, 64, 77, 0.9);
}
.avatar.show-title::after {
  display: none;
  content: "";
  position: absolute;
  bottom: 100%;
  top: auto;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -2px);
  width: 0;
  height: 0;
  overflow: hidden;
  border: 4px solid transparent;
  border-bottom-width: 0;
  border-top-color: rgba(59, 64, 77, 0.9);
}
.avatar.show-title:hover::after,
.avatar.show-title:hover::before {
  display: block;
}
.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
}
.badge {
  display: inline-block;
  margin-right: 5px;
  padding: 0 5px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  background-color: #e9e9e9;
  color: #595959;
  border-radius: 3px;
}
.badge.badge-xl {
  padding: 0 10px;
}
.badge.badge-primary {
  background-color: #22b8ff;
  color: #fff;
}
.badge.badge-primary.badge-light {
  color: #22b8ff;
  background-color: rgba(34, 184, 255, 0.2);
}
.badge.badge-warn {
  background-color: #faad14;
  color: #fff;
}
.badge.badge-warn.badge-light {
  color: #faad14;
  background-color: rgba(250, 173, 20, 0.2);
}
.badge.badge-strong {
  background-color: #46cadb;
  color: #fff;
}
.badge.badge-strong.badge-light {
  color: #46cadb;
  background-color: rgba(70, 202, 219, 0.2);
}
.badge.badge-danger {
  background-color: #ff3b30;
  color: #fff;
}
.badge.badge-danger.badge-light {
  color: #ff3b30;
  background-color: rgba(255, 59, 48, 0.2);
}
.badge.badge-light {
  color: #818794;
}
.badge.badge-reverse {
  background-color: #dde0e4;
  color: #fff;
}
.btn {
  display: inline-block;
  line-height: 1;
  padding: 12px 28px;
  font-size: 14px;
  color: #333;
  border-radius: 0.2em;
  background: #fff none repeat-y;
  border: 1px solid #dddddd;
  text-align: center;
  transition: background 0.15s ease-in-out;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
}
.btn .svg-icon {
  margin-right: 5px;
  width: 14px;
  height: 14px;
  fill: rgba(0, 0, 0, 0.6);
  vertical-align: bottom;
}
.btn.btn-primary {
  color: #fff;
  background-color: #22b8ff;
  border-color: transparent;
}
.btn.btn-primary:active {
  color: rgba(255, 255, 255, 0.6);
}
.btn.btn-primary .svg-icon {
  fill: #fff;
}
.btn.btn-strong {
  color: #fff;
  background-color: #46cadb;
  border-color: transparent;
}
.btn.btn-strong:active {
  color: rgba(255, 255, 255, 0.6);
}
.btn.btn-strong .svg-icon {
  fill: #fff;
}
.btn.btn-danger {
  color: rgba(255, 255, 255, 0.8);
  background-color: #ff3b30;
  border-color: transparent;
}
.btn.btn-danger:active {
  color: rgba(255, 255, 255, 0.6);
}
.btn.btn-danger .svg-icon {
  fill: #fff;
}
.btn.btn-warn {
  color: rgba(255, 255, 255, 0.9);
  background-color: #faad14;
  border-color: transparent;
}
.btn.btn-warn:active {
  color: rgba(255, 255, 255, 0.6);
}
.btn.btn-warn .svg-icon {
  fill: #fff;
}
.btn.btn-xs {
  font-size: 12px;
  padding: 5px 10px;
}
.btn.btn-sm {
  font-size: 12px;
  padding: 9px 20px;
}
.btn.btn-xl {
  padding: 15px 40px;
  font-size: 16px;
}
.btn.btn-long {
  padding-left: 50px;
  padding-right: 50px;
}
.btn.btn-block {
  display: block;
  margin: 15px 0;
  width: 100%;
}
.btn.btn-circle {
  border-radius: 20px;
}
.btn.disabled,
.btn[disabled] {
  background-color: #d4d4d4;
  cursor: not-allowed;
  color: #fff;
}
.btn.disabled:hover,
.btn[disabled]:hover {
  background-image: none;
}
.btn:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
}
.btn:focus {
  outline: 0 none;
}
.btn:active {
  outline: 0 none;
  background-image: linear-gradient(rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08));
}
.btn.btn-loading {
  background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.3));
  cursor: default;
}
.icon-btn {
  display: inline-block;
  font-size: 0;
  padding: 5px;
  cursor: pointer;
  vertical-align: middle;
}
.icon-btn[data-title] .svg-icon {
  fill: #d1d4d8;
}
.icon-btn[data-title]:hover .svg-icon {
  fill: #22b8ff;
}
.icon-btn + .icon-btn {
  margin-left: 5px;
}
.icon-btn .svg-icon {
  width: 12px;
  height: 12px;
  fill: rgba(0, 0, 0, 0.6);
  vertical-align: bottom;
}
.icon-btn.icon-btn-primary .svg-icon {
  fill: #22b8ff;
}
.icon-btn.icon-btn-strong .svg-icon {
  fill: #46cadb;
}
.icon-btn.icon-btn-danger .svg-icon {
  fill: #ff3b30;
}
.icon-btn.icon-btn-warn .svg-icon {
  fill: #faad14;
}
.upload-progress {
  margin-bottom: 10px;
  height: 1em;
  transition: width 0.2s;
  background-color: rgba(66, 192, 252, 0.5);
}
.uploader {
  border: 1px solid #dde0e5;
  border-radius: 3px;
  padding: 5px 10px 0;
}
.uploader .file-upload {
  margin-bottom: 5px;
  line-height: 20px;
  color: #22b8ff;
}
.uploader .file-upload .svg-icon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
  fill: #22b8ff;
}
.uploader .image-list {
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
}
.uploader .image-list li {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  padding: 5px;
  position: relative;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 2px;
  text-align: center;
}
.uploader .image-list li:hover .image-remove {
  display: block;
}
.uploader .image-list img {
  max-width: 90px;
  max-height: 90px;
}
.uploader .image-log {
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  font-size: 0;
}
.uploader .image-log:hover .svg-icon {
  fill: #fff;
}
.uploader .image-log .svg-icon {
  width: 16px;
  height: 16px;
  fill: #fff;
}
.uploader .image-remove {
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  font-size: 0;
}
.uploader .image-remove:hover .svg-icon {
  fill: #fff;
}
.uploader .image-remove .svg-icon {
  width: 16px;
  height: 16px;
  fill: #fff;
}
.uploader .file-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.uploader .file-list li {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 5px;
}
.uploader .file-list li:hover .file-edit-btn {
  display: inline;
}
.uploader .file-list a {
  margin: 0 5px;
}
.uploader .file-size {
  color: #999;
}
.uploader .svg-icon {
  width: 14px;
  height: 14px;
  fill: #d1d4d9;
}
.uploader .file-edit-btn {
  display: none;
  margin-left: 10px;
  cursor: pointer;
}
.uploader .file-edit-btn:hover .svg-icon {
  fill: #22b8ff;
}
.toggle-btn {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 26px;
  padding: 0 8px;
  text-align: right;
  border-radius: 13px;
  border: 0;
  background-color: #e0e4ee;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;
}
.toggle-btn.small {
  width: 32px;
  height: 20px;
}
.toggle-btn.small.active::before {
  left: calc(100% - 18px);
}
.toggle-btn.small::before {
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
}
.toggle-btn.small::after {
  line-height: 20px;
}
.toggle-btn.readonly {
  cursor: not-allowed;
}
.toggle-btn::after {
  content: attr(data-no);
  line-height: 26px;
  font-size: 12px;
  text-align: right;
  color: #fff;
}
.toggle-btn.active {
  text-align: left;
  border-color: #22b8ff;
  background: #22b8ff linear-gradient(246deg, #4293fc, #4ea9f9 34%, #4ea9f9 34%, #58bdf7 64%, #58bdf7 64%, #5ac1f7 70%, #64d5f5) repeat 0 0;
  box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.toggle-btn.active::before {
  left: calc(100% - 23px);
}
.toggle-btn.active::after {
  content: attr(data-yes);
  color: #fff;
}
.toggle-btn::before {
  position: absolute;
  content: "";
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  transition: left 0.3s ease;
}
.btn-group .btn + .btn {
  margin-left: 10px;
}
.btn-group.center {
  text-align: center;
}
.btn-group.right {
  text-align: right;
}
.alert {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.alert .svg-icon {
  margin-right: 10px;
  fill: #22b8ff;
}
.time-selector {
  display: flex;
  align-items: center;
}
.time-selector .form-control {
  width: 50px;
}
.time-selector .time-sep {
  margin: 0 5px;
}
.dropdown-control {
  position: relative;
}
.dropdown-control .dropdown-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  border: 1px solid #d0d0d0;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.dropdown-control .dropdown-view::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  line-height: 0;
  border: 5px solid transparent;
  border-top-color: #8c8c8c;
  border-bottom-width: 0;
}
.dropdown-control .dropdown-selected {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #8c8c8c;
}
.dropdown-control .dropdown-selected.has-value {
  color: #595959;
}
.dropdown-control .dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: -1px;
  width: 100%;
  max-height: 180px;
  overflow: auto;
  border: 1px solid #d0d0d0;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}
.dropdown-control .dropdown-option {
  height: 36px;
  padding: 0 10px;
  line-height: 36px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-control .dropdown-option.dropdown-option-empty {
  color: #8c8c8c;
}
.dropdown-control .dropdown-option:hover {
  background-color: #f6f6f6;
}
.form-control {
  width: 100%;
  padding: 0 10px;
  height: 32px;
  border: 1px solid #d9d9d9;
  color: #595959;
  background-color: #fff;
  font-size: inherit;
  border-radius: 3px;
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.form-control.form-sm {
  height: 30px;
}
.form-control.form-control-text {
  line-height: 32px;
}
.form-control.form-control-text.form-sm {
  line-height: 30px;
}
.form-control:focus {
  outline: 0 none;
  border-color: #22b8ff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}
.form-control.form-error {
  border-color: #ff3b30;
  background-color: rgba(255, 59, 48, 0.2);
}
.form-control.form-warn {
  border-color: #faad14;
  background-color: rgba(250, 173, 20, 0.2);
}
.form-control.form-default {
  background-color: rgba(0, 0, 0, 0.02);
}
.form-control[readonly] {
  background-color: #f0f0f0;
}
.form-control[readonly]:focus {
  border-color: #d0d0d0;
}
textarea.form-control {
  height: 80px;
  padding: 5px 10px;
  resize: vertical;
}
.block-radio {
  padding-left: 5px;
}
.block-radio .radio-item {
  display: block;
}
.radio-item {
  height: 36px;
  line-height: 36px;
  position: relative;
  margin-right: 10px;
  padding-left: 25px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.radio-item::before {
  position: absolute;
  left: 0;
  top: 8px;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #fff;
}
.radio-item::after {
  display: none;
  position: absolute;
  left: 3px;
  top: 11px;
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #22b8ff;
}
.radio-item.checked::before {
  border-color: #22b8ff;
}
.radio-item.checked::after {
  display: block;
}
.radio-item.disabled {
  cursor: not-allowed;
  color: #c0c0c0;
  background-color: transparent;
}
.radio-item.disabled::before {
  background-color: transparent;
}
.radio-item.radio-simple {
  font-size: 0;
  padding-left: 20px;
  margin-right: 0;
}
.radio-item.radio-sm {
  padding-left: 22px;
}
.radio-item.radio-sm::before {
  width: 14px;
  height: 14px;
  top: 11px;
  left: 3px;
}
.radio-item.radio-sm::after {
  left: 7px;
  top: 15px;
  width: 6px;
  height: 6px;
}
.checkbox-item {
  position: relative;
  height: 36px;
  line-height: 36px;
  margin-right: 10px;
  padding-left: 20px;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
}
.checkbox-item:hover::before {
  border-color: #22b8ff;
}
.checkbox-item.checkbox-simple {
  width: 16px;
  height: 16px;
  font-size: 0;
  padding-left: 16px;
  line-height: 16px;
  margin-right: 0;
}
.checkbox-item.checkbox-simple::before {
  top: 0;
}
.checkbox-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 2px;
  vertical-align: middle;
  background-color: #fff;
}
.checkbox-item.checked::before {
  border-color: #22b8ff;
  background: #22b8ff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAjUlEQVR4AWMYMWAUjIL///8rAHE/EOcDsQC9LTcA4vf/EWAB/S1HBRfoazkmaBhIyxeMWj5q+ajl+AxaD8TngTiAfpYjDNuAZkgCXYMdVDxiMSyB9pYjDHX4jx0k0C3BgSzD5QjKLafcEfWELKe5I/BaTn9HICynsyPo36JJoI/lhLPoBHBuGG5gFIwCAHaUtjpX3RYzAAAAAElFTkSuQmCC) no-repeat center / 75%;
}
.checkbox-item.readonly {
  cursor: default;
}
.checkbox-item.readonly:hover::before {
  border-color: #ccc;
}
.checkbox-item.readonly::before {
  background-color: #f3f4f5;
}
.checkbox-item.readonly.checked::before {
  background-color: #22b8ff;
}
.checkbox-item.readonly.checked:hover::before {
  border-color: #22b8ff;
}
.checkbox-item.disabled {
  cursor: not-allowed;
  color: #c0c0c0;
  background-color: transparent;
}
.checkbox-item.disabled::before {
  background-color: transparent;
}
@keyframes modal-fade-center {
  0% {
    opacity: 0;
    transform: scale(0.6) translateY(-30%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes modal-fade-right {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes modal-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.modal-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: none;
  background-color: rgba(0, 0, 0, 0.25);
  overflow: auto;
}
.modal-bg.modal-opened {
  display: block;
}
.modal-bg.modal-center .modal {
  margin: 100px auto;
  animation: 0.3s ease-in-out 0s 1 alternate modal-fade-center;
}
.modal-bg.modal-right .modal {
  float: right;
  height: 100%;
  animation: 0.3s ease-in-out 0s 1 alternate modal-fade-right;
}
.modal-bg.modal-left .modal {
  float: left;
  height: 100%;
  animation: 0.3s ease-in-out 0s 1 alternate modal-fade-left;
}
.modal-bg .modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 540px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}
.modal-bg .close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #8c8c8c;
}
.modal-bg .close:hover {
  color: #595959;
}
.modal-bg .close .svg-icon {
  fill: currentColor;
}
.modal-bg .modal-header {
  flex: 0 0 40px;
  border-bottom: 1px solid #ccc;
}
.modal-bg .modal-header h3 {
  margin: 0;
  line-height: 40px;
  padding: 0 10px;
  font-weight: normal;
  font-size: 16px;
}
.modal-bg .modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 50px;
  padding: 0 15px;
  background-color: #fff;
}
.modal-bg .modal-footer .btn + .btn {
  margin-left: 15px;
}
.modal-bg .modal-body {
  flex: 1;
  padding: 20px 20px;
  background-color: #fff;
}
.modal-bg .modal-body.scroll {
  overflow: auto;
}
.modal-bg .modal-body h3 {
  margin-top: 0;
}
.form-group,
.form-actions {
  margin-bottom: 10px;
}
.form-actions.center {
  text-align: center;
}
.form-actions.right {
  text-align: right;
}
.form-actions .btn + .btn {
  margin-left: 10px;
}
.form-required .form-label {
  position: relative;
}
.form-required .form-label::before {
  position: absolute;
  left: -3px;
  content: "*";
  font-weight: 600;
  color: #ff3b30;
}
.form-valid-warn .form-label {
  position: relative;
  z-index: 1;
  color: #ff3b30;
  cursor: help;
}
.form-valid-warn .form-label:hover .form-error {
  display: block;
}
.form-valid-warn .form-label .form-error {
  display: none;
  position: absolute;
  left: 0;
  bottom: 100%;
  z-index: 10;
  white-space: nowrap;
  margin-bottom: 10px;
  padding: 3px 10px;
  border: 1px solid #ff3b30;
  border-radius: 3px;
  background-color: #fff;
}
.form-valid-warn .form-label .form-error::before,
.form-valid-warn .form-label .form-error::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 6px;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-bottom-width: 0;
  border-top-color: #fff;
}
.form-valid-warn .form-label .form-error::before {
  border-top-color: #ff3b30;
}
.form-valid-warn .form-label .form-error::after {
  transform: translateY(-1px);
}
.form-label {
  padding-left: 5px;
  line-height: 32px;
  align-self: flex-start;
}
.form-label::after {
  content: "\ff1a";
}
.form-label.empty::after {
  content: " ";
}
.form-readonly .form-label {
  color: #999;
}
.form-readonly .form-label::after {
  display: none;
}
.form-tips {
  margin: 0;
  color: #BFBFBF;
  font-size: 12px;
}
.form-text {
  min-height: 32px;
  padding-left: 5px;
  text-indent: 1em;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
pre.form-text {
  margin-top: 0;
  font-family: inherit;
  white-space: pre-wrap;
}
.textarea-preview {
  margin: 0;
  white-space: pre-wrap;
  font-family: inherit;
  line-height: 1.6;
}
.form-content {
  padding-right: 5px;
}
.form-content p {
  margin-bottom: 0;
}
.form-content .form-text {
  margin-bottom: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}
.form-horizontal .form-group {
  display: flex;
  align-items: center;
}
.form-horizontal .form-group.form-items-start {
  align-items: start;
}
.form-horizontal .form-group.form-items-start .form-label {
  align-self: flex-start;
}
.form-horizontal .form-label {
  width: 8em;
}
.form-horizontal .form-content {
  flex: 1;
}
.form-horizontal .form-text {
  padding-left: 0;
  text-indent: 0;
}
.form-horizontal .input-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-horizontal .input-group .form-control {
  flex: 1;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-horizontal .input-group .btn {
  box-shadow: none;
  height: 32px;
  border-radius: 0;
}
.form-horizontal .input-group .btn:last-child {
  border-radius: 0 0.2em 0.2em 0;
}
.form-horizontal .form-action {
  flex: 1;
  padding: 20px 0 10px;
}
.form-inline {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.form-inline .form-group {
  flex: 1;
  margin-bottom: 0;
}
.form-inline .form-group.form-shrink {
  flex: 0;
}
.form-inline .form-group + .form-group {
  margin-left: 10px;
}
.form {
  margin-bottom: 15px;
}
.form-inner {
  background-color: rgba(0, 0, 0, 0.05);
}
.form-inner .form-inner-title {
  font-weight: bold;
  margin: 0;
  padding: 5px 5px 10px;
  font-size: 1.2em;
}
.form-split {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-split .form-group {
  flex: 1;
}
.form-split .form-group.form-shrink {
  flex: 0;
}
.form-split .form-group + .form-group {
  margin-left: 15px;
}
.form-split .form-actions {
  flex: 1;
}
.form-nodes {
  overflow: hidden;
}
.form-nodes .node-tag {
  position: relative;
  float: left;
  display: flex;
  align-items: center;
  margin: 0 10px 0 0;
  height: 34px;
  padding-right: 20px;
  font-size: 12px;
}
.form-nodes .node-tag .org-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border: 1px solid #22b8ff;
  border-radius: 17px;
  background-color: #fff;
}
.form-nodes .node-tag .org-icon img {
  width: 32px;
  height: 32px;
}
.form-nodes .node-tag .org-icon .svg-icon {
  width: 18px;
  height: 18px;
  fill: #22b8ff;
}
.form-nodes .node-tag:hover {
  background-color: #f5f5f5;
  border-radius: 17px 4px 4px 17px;
}
.form-nodes .node-tag:hover .node-close {
  display: flex;
}
.form-nodes .node-close {
  position: absolute;
  top: 4px;
  right: 4px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.form-nodes .node-close .svg-icon {
  fill: #bdc2ca;
  width: 12px;
  height: 12px;
}
.form-nodes .node-add {
  float: left;
  display: flex;
  align-items: center;
  color: #22b8ff;
  font-size: 12px;
  cursor: pointer;
}
.form-nodes .node-add .add-icon {
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border: 1px solid #22b8ff;
  border-radius: 16px;
}
.form-nodes .node-add .add-icon .svg-icon {
  fill: #22b8ff;
}
.table-container {
  max-width: 100%;
  overflow: auto;
}
.table-container .table {
  max-width: 100%;
}
.table-container td,
.table-container th {
  white-space: nowrap;
}
.table {
  margin-bottom: 20px;
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  border-width: 1px 0;
}
.table thead tr {
  border-bottom: 1px solid #e9e9e9;
  height: 45px;
  background-color: #fafafa;
}
.table tbody tr {
  border-bottom: 1px solid #e9e9e9;
}
.table th,
.table td {
  text-align: left;
  padding: 8px 10px;
  word-break: break-word;
  overflow-wrap: break-word;
}
.table th {
  white-space: nowrap;
  word-break: normal;
  font-weight: 600;
}
.table tbody tr:hover td {
  background-color: #fafafa;
}
.table tbody tr.actived td {
  background-color: #22b8ff;
  color: #fff;
}
.table tr.disabled {
  cursor: not-allowed;
  color: #d1d4d8;
}
.table tr.disabled .checkbox-item.disabled::before {
  background: #ece8e8;
}
.table tr.disabled:hover td {
  background: #fff;
}
.table tr.disabled .file-name {
  cursor: not-allowed;
}
.table tr.disabled .svg-icon {
  fill: #d1d4d8;
}
.react-datepicker__input-container {
  display: block !important;
}
.autocomplete {
  position: relative;
  z-index: 5;
}
.autocomplete.autocomplete-opened {
  z-index: 6;
}
.autocomplete.disabled > .form-control {
  border-color: #eee;
  color: #ccc;
  cursor: text;
}
.autocomplete .empty {
  color: #999;
}
.autocomplete .form-control-text {
  cursor: pointer;
}
.autocomplete .autocomplete-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 240px;
  width: 100%;
  margin-top: 2px;
  max-height: 200px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: auto;
}
.autocomplete .autocomplete-dropdown .form-control {
  margin-bottom: 10px;
}
.autocomplete ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.autocomplete li {
  padding: 0 10px;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  white-space: nowrap;
}
.autocomplete li.autocomplete-empty {
  color: #999;
}
.autocomplete li + li {
  border-top: 1px dashed #ddd;
}
.autocomplete li:hover {
  background-color: #f3f3f3;
}
.pagination {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 12px;
}
.pagination .pg-size-control {
  margin-left: 10px;
  line-height: 30px;
  font-size: 14px;
}
.pagination .pg-size-control .pg-size {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: top;
}
.pagination .pg-size-control .pg-size.active {
  color: #22b8ff;
}
.pagination form {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.pagination form .form-control {
  margin: 0 5px;
  width: 60px;
  height: 30px;
  text-align: center;
}
.pagination .pg-nav i {
  margin: 0 5px;
}
.pagination .pg-nav span {
  position: relative;
  display: inline-block;
  height: 30px;
  min-width: 30px;
  padding: 0 10px;
  line-height: 28px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  cursor: pointer;
}
.pagination .pg-nav span:hover {
  border-color: #f1f1f1;
  background: #f1f1f1;
}
.pagination .pg-nav span + span {
  margin-left: 5px;
}
.pagination .pg-nav .pg-prev {
  padding-left: 24px;
}
.pagination .pg-nav .pg-prev::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 0;
  height: 0;
  overflow: hidden;
  border: 7px solid transparent;
  border-left-width: 0;
  border-right-color: #333;
}
.pagination .pg-nav .pg-next {
  padding-right: 24px;
}
.pagination .pg-nav .pg-next::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  width: 0;
  height: 0;
  overflow: hidden;
  border: 7px solid transparent;
  border-right-width: 0;
  border-left-color: #333;
}
.pagination .pg-nav .current {
  background-color: #22b8ff;
  color: #fff;
  border-color: #22b8ff;
}
.pagination .pg-nav .current:hover {
  background-color: #00b0fe;
  border-color: #00b0fe;
}
.pagination .pg-nav .disabled {
  background-color: #f0f0f0;
  color: #8c8c8c;
  cursor: text;
}
.pagination .pg-nav .disabled:hover {
  border-color: #ddd;
}
.pagination .pg-nav .disabled.pg-prev::before {
  border-right-color: #8c8c8c;
}
.pagination .pg-nav .disabled.pg-next::after {
  border-left-color: #8c8c8c;
}
.pagination-sm {
  display: flex;
  justify-content: center;
  user-select: none;
  padding: 0;
  margin-top: 5px;
}
.pagination-sm.left {
  justify-content: flex-start;
}
.pagination-sm.right {
  justify-content: flex-end;
}
.pagination-sm .pg-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pagination-sm li {
  display: inline-block;
}
.pagination-sm .btn-left,
.pagination-sm .btn-right {
  cursor: pointer;
}
.pagination-sm .btn-left:hover .svg-icon,
.pagination-sm .btn-right:hover .svg-icon {
  fill: #ec9439;
}
.pagination-sm .page {
  padding: 0 10px;
}
.breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  font-size: 12px;
  background-color: #fff;
}
.breadcrumb .svg-icon {
  float: left;
  width: 16px;
  height: 16px;
  margin: 0 4px;
}
.breadcrumb li {
  float: left;
  line-height: 16px;
}
.breadcrumb li > a {
  color: #22b8ff;
}
.breadcrumb li > a:hover {
  color: #22b8ff;
}
.breadcrumb span {
  color: #999;
}
.org-selector .btn-group {
  margin-top: 20px;
  text-align: center;
}
.org-selector .cp-folders {
  height: 404px;
  overflow: auto;
}
.icon-search {
  position: relative;
}
.icon-search .form-control {
  padding-left: 36px;
}
.icon-search .svg-icon {
  display: none;
}
.icon-search::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADIklEQVR4Ae1ZA7BcUQzd2rZte1zb7aC2bdu2bds2xrVt29rtSZlmlu/dd7ftf5k5//9Vcs5Fks132GbebLNtzpw58WbMmFF62rRptaZPn94G6Am0wuPqQPEJEyZE/+tIz549OzHIdQWOgqwTcHkC3vMJ2EWi5s6dGzOoxPv16xcWRDoCbzwR9iHmAXardlDIg0BuEDhPRBTg4KxZs1JoI0+rBvLv3azobWAGUBGPs9F9wO9wkydPTojP5KR7gMcL8fupGxHPZs6cWUTHyo9xE/wqUMPlcoXy00e4Hxf7sViAL0AzK8m3cUN+MBEyePmjgfASKQK7VVI5eUqB5JwF+4zHdcz6pV2DnyFCxCsgszLySHcR4fi+CFJL8e4OF/6Pq3TeRRyb8Ypcy53YxuPgKFVVQT4GHL9gji+tWLEijMMCQyqNLWKdQ60JbVZAc7G1FS3Och1FvOJmL+9e5vCkw2LD7oYXuzDTsLMpU6bEEr1NX01VfiGL+cTwkcVKlBGXKqemSl9VxE1vdCUacUfUvOkQgJYiE487derUQkYF9GWX6bFDk1GbLQTUNHqExjFHFxwaTVT9Nkad9OK9u8ZWPYZIpXWMCmjIHDmpWgbjDlAyUZKFcBZTaspClUUWyme4tIs60FzT+Z/PYr6mOqCqEu/UUYkR5yWLucnshWrJ7wH16Tp6IWW7vmDBgihw8pw53KeSsGxdxOrT35FVnMmuMq1Z9H1gh1j9Ucq+kcH5PSbgPWUGxUdnhFikp0inCVQGKMyrIxU2BMiuYuV/DAZcAmV1TCXe0ATB5P1a5ma+dMqyognno0VAJ7AAw6tkASxEOPhpgd8PvYwd55MInZO5D8Bimr5RNpGfoSr+czIHPHFD2knn3r0I/bNR5w+SZ2liDbwSr0s8AgpDeGoa32gRwabTnU1Mp78Ay3m2cScCWKpahDweSXz9f0DgObAAvVZa7oeLoEGxLhHyaCWibvKHoFnADmA5np8EdAEK+DProXG7OxGysQuuGRCBx2tsEcEQAeI3hIj+GkKrTRRcBARMVR1DS4KgSQmwAYLi//mqbbbZ9hV25hmDvd8TGwAAAABJRU5ErkJggg==") 50% / cover no-repeat;
}
.org-transport {
  width: 520px;
  height: 404px;
  display: flex;
  justify-content: space-between;
}
.org-transport .org-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 353px;
}
.org-transport .org-empty .icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border: 2px solid #d1d4d9;
  border-radius: 45px;
}
.org-transport .org-empty .svg-icon {
  width: 50px;
  height: 50px;
  fill: #d1d4d9;
}
.org-transport .org-empty p {
  color: #888c9d;
}
.org-transport .org-empty p em {
  font-style: normal;
  color: #ff3568;
}
.org-transport .snipper {
  display: block;
  margin: 100px auto;
  height: 40px;
}
.org-transport .org-source {
  flex: 1;
}
.org-transport .no-search .org-select {
  margin-top: 0;
  height: 404px;
}
.org-transport .org-select {
  margin-top: 15px;
  height: 353px;
  overflow: auto;
}
.org-transport .org-selected {
  margin-left: 20px;
  width: 220px;
  padding: 16px;
  border: 1px solid #eee;
}
.org-transport .org-selected .selected-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
}
.org-transport .org-selected .selected-header .selected-count {
  color: #999;
}
.org-transport .org-selected .selected-header .selected-clear {
  color: #22b8ff;
  cursor: pointer;
}
.org-transport .org-selected .org-list {
  max-height: 400px;
}
.org-transport .org-selected .org-list li {
  cursor: default;
}
.org-transport .select-list {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 354px;
  overflow: auto;
}
.org-transport .select-item {
  margin-top: 14px;
  position: relative;
  padding-right: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #f5f5f5;
  border-radius: 17px 4px 4px 17px;
}
.org-transport .select-item .org-icon {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  background: #fff;
  border-radius: 17px;
  text-align: center;
  line-height: 32px;
  border: 1px solid #22b8ff;
  font-size: 0;
  vertical-align: middle;
}
.org-transport .select-item .org-icon .svg-icon {
  fill: #22b8ff;
  width: 18px;
  height: 18px;
}
.org-transport .select-item .org-icon img {
  width: 32px;
  height: 32px;
}
.org-transport .remove-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 0;
  cursor: pointer;
}
.org-transport .remove-icon .svg-icon {
  width: 14px;
  height: 14px;
  fill: #8c8c8c;
}
.user-list {
  margin: 15px 0 0;
  height: 353px;
  padding: 0;
  list-style: none;
  overflow: auto;
}
.user-list .remove-icon {
  margin-right: 5px;
}
.user-list li {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 5px;
  cursor: pointer;
}
.user-list li:hover {
  background-color: #f5f5f5;
}
.user-list .checkbox-item {
  margin-right: 10px;
}
.user-view-single {
  white-space: nowrap;
}
.user-view-single + .user-view-single {
  margin-top: 10px;
}
.user-view-single .user-code {
  color: #999;
}
.user-view-thumb .avatar + .avatar {
  margin-left: 10px;
}
.user-view-line {
  overflow: hidden;
}
.user-view-line .user-view-single {
  float: left;
  margin: 0 20px 10px 0;
}
.empty-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 2px solid #22b8ff;
  border-radius: 50px;
}
.empty-icon-box .svg-icon {
  fill: #22b8ff;
  width: 50px;
  height: 50px;
}
.importance-new {
  display: inline-block;
}
.importance-new .level-0 .svg-icon {
  fill: #f69623;
}
.importance-new .level-1 .svg-icon {
  fill: #f56e23;
}
.importance-new .level-2 .svg-icon {
  fill: #f53d23;
}
.importance {
  display: inline-block;
  padding: 2px 10px;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
}
.importance.level-0 {
  background-color: #f8be1c;
}
.importance.level-0::before {
  content: "普通";
}
.importance.level-1 {
  background-color: #f69623;
}
.importance.level-1::before {
  content: "普通";
}
.importance.level-2 {
  background-color: #f56e23;
}
.importance.level-2::before {
  content: "紧急";
}
.importance.level-3 {
  background-color: #f53d23;
}
.importance.level-3::before {
  content: "特急";
}
.expression-editor {
  font-family: Menlo, monospace, Consolas, "Courier New";
}
.expression-editor textarea {
  display: block;
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 16px;
  font-family: inherit;
}
.expression-editor textarea:focus {
  outline: none;
}
.expression-editor .functions {
  margin-bottom: -1px;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 5px;
}
.expression-editor .variables {
  margin-bottom: -1px;
  padding: 5px;
  border: 1px solid #ddd;
  overflow: hidden;
}
.expression-editor .var-field {
  float: left;
  margin: 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.expression-editor .var-field span {
  display: block;
  text-align: center;
}
.expression-editor .field-key {
  font-weight: 600;
}
.expression-editor .field-label {
  border-top: 1px solid #ddd;
}
.expression-editor .console {
  margin-top: -1px;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #1d1f21;
  color: #c5c8c6;
}
.expression-editor .error {
  color: #ff3b30;
}
.expression-editor .operator,
.expression-editor .brackets {
  color: #88beb7;
  margin: 0 1px;
}
.expression-editor .string {
  font-style: italic;
  color: #b5be63;
}
.expression-editor .number,
.expression-editor .boolean {
  color: #e0935a;
}
.expression-editor .function-name {
  color: #80a2bf;
  font-weight: 600;
}
.expression-editor .express-tips {
  margin: 0 5px;
  clear: both;
  color: #8c8c8c;
}
.valid-error {
  color: #ff3b30;
}
.tag-controller .tag,
.tag-controller .btn-add,
.tag-controller .add-tag-box {
  margin-bottom: 6px;
}
.tag-controller .tag {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  padding: 3px 10px;
  border-radius: 3px;
  background-color: #faad14;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
}
.tag-controller .tag:hover .tag-close {
  display: block;
}
.tag-controller .tag-close {
  display: none;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 14px;
  height: 14px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  border-radius: 7px;
  background-color: #fff;
  color: #595959;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.tag-controller .tag-inline {
  display: inline-block;
  min-width: 150px;
}
.tag-controller .tag-selected {
  border: 1px solid #ddd;
  padding: 5px 10px 0;
  min-height: 35px;
  border-radius: 5px;
}
.tag-controller .tag-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
}
.tag-controller .tag-list .tag {
  cursor: pointer;
}
.tag-controller .tag-list .tag.active {
  background-color: rgba(250, 173, 20, 0.5);
  cursor: text;
}
[data-title]:not(button) {
  position: relative;
  cursor: pointer;
}
[data-title]:not(button).pos-top::before {
  top: auto;
  bottom: 100%;
  transform: translate(-50%, -2px);
}
[data-title]:not(button).pos-top::after {
  top: auto;
  bottom: 100%;
  border-top-width: 4px;
  border-bottom-width: 0;
  border-top-color: rgba(59, 64, 77, 0.9);
  border-bottom-color: transparent;
}
[data-title]:not(button)::before {
  display: none;
  content: attr(data-title);
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, 6px);
  padding: 4px 8px;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 3px;
  background-color: rgba(59, 64, 77, 0.9);
}
[data-title]:not(button)::after {
  display: none;
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, 2px);
  width: 0;
  height: 0;
  overflow: hidden;
  border: 4px solid transparent;
  border-top-width: 0;
  border-bottom-color: rgba(59, 64, 77, 0.9);
}
[data-title]:not(button):hover::before,
[data-title]:not(button):hover::after {
  display: block;
}
[data-space]::before {
  width: 230px;
  white-space: normal;
  line-height: 20px;
}
.svg-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
a {
  color: #22b8ff;
  text-decoration: none;
  cursor: pointer;
}
a .svg-icon {
  fill: currentColor;
}
.link-btn-group .link-btn + .link-btn {
  margin-left: 10px;
}
.link-btn {
  color: #22b8ff;
  white-space: nowrap;
  cursor: pointer;
}
.link-btn:hover {
  color: #22b8ff;
}
input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
}
hr {
  border: 1px solid #ddd;
  border-width: 1px 0 0;
}
p {
  margin: 10px 0;
}
img {
  max-width: 100%;
  max-height: 100%;
}
html {
  min-height: 100%;
}
body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: #595959;
  background-color: #f3f6fb;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body ::placeholder,
body .ant-input::placeholder {
  color: #999;
}
.opt {
  padding: 0 10px;
  color: #22b8ff;
  cursor: pointer;
  white-space: nowrap;
}
.opt:first-child {
  padding-left: 0;
}
.opt:last-child {
  padding-right: 0;
}
.opt + .opt {
  position: relative;
}
.opt + .opt::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  content: "";
  width: 1px;
  height: 14px;
  background-color: #ededed;
}
.opt.danger {
  color: #ff3b30;
}
.add-line {
  display: block;
  height: 36px;
  border-radius: 5px;
  border: 1px dashed #ededed;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  margin-top: 10px;
}
.add-line:hover {
  border-color: #22b8ff;
  color: #22b8ff;
}
:root body {
  height: auto;
}
:root body .ant-btn-primary {
  text-shadow: none;
}
:root body .ant-modal-close-x {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAJ1BMVEUAAACPj4+Li4uMjIyLi4uMjIyMjIyMjIyNjY2MjIyMjIyLi4uMjIx2lKwKAAAADXRSTlMAEI/vz1CQ/2Dg3/DQoQOXrwAAAJ9JREFUeNrtljEShSAMBUGUoHj/8xqLPylSrO9XFqYxE3fHAWKGokdd2ro9Abe1LdWf3TwG8+PmuifNwiDemme7hUG87b+UjICSwTwbAaQC8blEfC4Cz4bwQvm0vjh9+/QDkltAbrLEo5F4MhIPRuLZCJ4NjdeFoS+BDN5W/eCY/+bVN69eOK/KobTx4elUfpT5z9Wh9nk+u5ycs9cixwUczhTnxbYeQAAAAABJRU5ErkJggg==") no-repeat center / 14px;
}
:root body .ant-modal-close-x .anticon {
  display: none;
}
:root body .ant-btn {
  box-shadow: none;
}
.flex-center {
  display: flex;
  align-items: center;
}
#app {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 68px;
}
.content {
  flex: 1;
}
.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
  height: 0;
  overflow: hidden;
}
.clearfix::after {
  clear: both;
}
.top-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  min-height: 68px;
  max-height: 68px;
  color: rgba(0, 0, 0, 0.9);
  background: #273d55 none no-repeat 0 0;
  background-size: 100% 68px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.top-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 0 40px;
}
.top-header .global-search {
  flex: 1;
}
.top-header .global-search form {
  display: flex;
  width: 320px;
  height: 32px;
}
.top-header .global-search input[type="text"] {
  flex: 1;
  margin: 0;
  height: 32px;
  padding: 0 15px;
  border: 0 none;
  font-size: 12px;
  background-color: #fff;
  border-radius: 16px 0 0 16px;
}
.top-header .global-search input[type="text"]:focus {
  outline: none;
}
.top-header .global-search .search-btn {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  background-color: #22b8ff;
  border-radius: 0 16px 16px 0;
}
.top-header .global-search .search-btn .svg-icon {
  fill: #fff;
}
.top-header .global-search .search-btn button {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  font-size: 100px;
  cursor: pointer;
}
.top-header .toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
}
.top-header .toolbar .svg-icon {
  fill: #fff;
}
.top-header .toolbar .tools {
  display: flex;
}
.top-header .toolbar .tools a {
  margin-right: 20px;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
}
.top-header .toolbar .tools .has-unread::before {
  position: absolute;
  top: -3px;
  right: -1px;
  content: "";
  width: 10px;
  height: 10px;
  background: #ff3b30;
  border-radius: 50%;
}
.top-header .user-ctrl {
  display: flex;
  align-items: center;
  color: #fff;
  position: relative;
  height: 68px;
}
.top-header .user-ctrl:hover .header-dropdown {
  display: block;
}
.top-header .header-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: -10px;
  margin: -5px 0 0;
  width: 140px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  list-style: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
.top-header .header-dropdown::before,
.top-header .header-dropdown::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -9px;
  right: 15px;
  border: 10px solid transparent;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.top-header .header-dropdown::before {
  border-bottom-color: #ddd;
  top: -10px;
}
.top-header .header-dropdown a {
  display: block;
  height: 40px;
  padding: 0 15px;
  line-height: 40px;
  color: #8c8c8c;
}
.top-header .header-dropdown a .svg-icon {
  fill: #8c8c8c;
}
.top-header .header-dropdown a:hover {
  background-color: #f3f3f3;
  color: #8c8c8c;
}
.top-header .header-dropdown li + li a {
  border-top: 1px solid #ddd;
}
.top-header .logo-path {
  position: relative;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2em;
}
.top-header .logo-path.only-img {
  margin-right: 48px;
  font-size: 0;
}
.top-header .logo-path .logo-img {
  height: 50px;
}
.top-header .logo-path .env {
  position: absolute;
  white-space: nowrap;
  left: 6px;
  top: 0;
  line-height: 14px;
  font-size: 12px;
  padding: 0 7px;
  border-radius: 10px;
  color: #fff;
}
.top-header .logo-path .env.dev {
  background: #76bd09;
}
.top-header .logo-path .env.test {
  background: #d21e1e;
}
.top-header .avatar {
  width: 34px;
  height: 34px;
}
.preview .top-header .env {
  display: none;
}
.wrap {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar {
  position: relative;
  z-index: 10;
  min-width: 240px;
  max-width: 240px;
  background-color: #fff;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
}
.navbar .nav-menu {
  padding: 16px 15px 0 15px;
}
.navbar .nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.navbar .svg-icon {
  width: 18px;
  height: 18px;
}
.navbar .menu li + li {
  margin-top: 10px;
}
.navbar .menu a {
  display: block;
  padding: 0 40px 0 20px;
  line-height: 42px;
  border-radius: 3px;
  font-size: 14px;
  color: #595959;
}
.navbar .menu a.folder-menu {
  font-weight: bold;
}
.navbar .menu a:hover,
.navbar .menu a.active {
  background-color: #46cadb;
  color: #fff;
}
.navbar .menu a:hover .svg-icon,
.navbar .menu a.active .svg-icon {
  fill: #fff;
}
.navbar .menu a .svg-icon {
  fill: #22b8ff;
}
.navbar .menu.single-menu a.active {
  background-color: rgba(0, 0, 0, 0.2);
}
.navbar .menu .svg-icon {
  margin-right: 5px;
  vertical-align: middle;
}
.auth-menu {
  margin: 15px -15px 0;
}
.auth-menu h3 {
  margin: 0 25px;
  padding: 15px 0 5px;
  font-size: 12px;
  font-weight: 400;
  color: #999;
  border-top: 2px solid #e3e5e8;
}
.menu-module > li {
  position: relative;
  padding: 0 15px;
}
.menu-module > li::after {
  content: "";
  display: block;
  height: 10px;
  background: transparent;
}
.menu-module > li.hover .menu-apps {
  display: block;
  padding: 20px 30px;
}
.menu-module > li.hover .module-name {
  background-color: #46cadb;
  color: #fff;
  border-radius: 3px;
}
.menu-module > li.hover .module-name .svg-icon {
  fill: #fff;
}
.menu-module .module-name {
  position: relative;
  padding-left: 20px;
  line-height: 42px;
}
.menu-module .module-name .svg-icon {
  fill: #22b8ff;
  margin-right: 5px;
}
.menu-module .module-name .svg-icon-right {
  position: absolute;
  right: 0;
  top: 12px;
  fill: #a0a8b5;
}
.menu-apps {
  display: none;
  position: fixed;
  top: 68px;
  left: 240px;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 20px 30px;
  background-color: #f3f6fb;
  list-style: none;
  overflow: auto;
}
.menu-apps > li {
  padding-bottom: 20px;
}
.menu-apps .app-name {
  font-size: 18px;
  margin-bottom: 20px;
}
.menu-apps::before {
  content: "";
  position: absolute;
  top: 0;
  left: -1px;
  width: 1px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
}
.menu-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-items::after {
  content: "";
  display: table;
  clear: both;
}
.menu-items > li {
  position: relative;
  float: left;
  display: flex;
  margin: 0 -1px -1px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 130px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.menu-items > li:hover .menu-link {
  display: flex;
}
.menu-items p {
  margin: 15px 0 0;
}
.menu-items .svg-icon {
  width: 42px;
  height: 42px;
  fill: #22b8ff;
}
.menu-items .menu-link {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  background: #fff;
  text-align: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}
.menu-items .menu-link a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 12px;
  color: #595959;
}
.menu-items .menu-link a:hover {
  background-color: #46cadb;
  color: #fff;
}
.header-fixed.no-breadcrumb .content {
  padding-top: 60px;
}
.header-fixed .content {
  padding-top: 90px;
}
.header-fixed .page-header {
  position: fixed;
  z-index: 10;
  margin-top: -90px;
  width: 100%;
}
.header-fixed .page-header.no-breadcrumb {
  margin-top: -60px;
}
.header-fixed .message-header {
  position: fixed;
  z-index: 10;
  margin-top: -90px;
  width: 100%;
}
.message-header {
  border-bottom: 1px solid #dde0e5;
  background-color: #fff;
  margin-bottom: 20px;
}
.message-header .container {
  margin: 0 auto;
  width: 920px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.message-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  color: #22b8ff;
}
.message-header .message-sort {
  width: 160px;
}
.message-header .form-control {
  height: 34px;
}
.message-header .message-status {
  float: left;
  margin: 0 18px;
  line-height: 57px;
  font-size: 17px;
  cursor: pointer;
  border-bottom: 3px solid #fff;
}
.message-header .message-status.active {
  line-height: 57px;
  color: #22b8ff;
  border-bottom-color: #22b8ff;
}
.page-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  height: 90px;
  padding: 40px 40px 0 20px;
  border-bottom: 1px solid #dde0e5;
  background-color: #fff;
}
.page-header.no-breadcrumb {
  height: 60px;
  padding-top: 0;
}
.page-header.lucency {
  border-bottom-color: transparent;
  background-color: transparent;
}
.page-header.lucency h1 {
  color: #595959;
}
.page-header.lucency .breadcrumb {
  background-color: transparent;
}
.page-header .breadcrumb {
  position: absolute;
  top: 21px;
  left: 20px;
}
.page-header h1,
.page-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  color: #22b8ff;
}
.page-header .svg-icon {
  fill: #8c8c8c;
}
.message-container {
  padding: 0 5px;
}
.message-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px;
  border-bottom: 1px solid #eee;
}
.message-control .svg-icon {
  margin-right: 5px;
  fill: #22b8ff;
  width: 20px;
  height: 20px;
}
.message-control .status-control {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.message-control .status-control span {
  margin-left: 20px;
}
.message-list {
  margin-bottom: 20px;
}
.message-item {
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 10px;
  border-bottom: 1px solid #eee;
  border-radius: 1px;
  cursor: pointer;
}
.message-item:hover {
  background-color: rgba(34, 184, 255, 0.05);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}
.message-item:hover p {
  color: #595959;
}
.message-type {
  margin: 0 15px;
  width: 44px;
  height: 44px;
  border-radius: 22px;
}
.message-content {
  flex: 1;
}
.message-content .content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.message-content .content-header .content-header-left {
  display: flex;
  align-items: center;
}
.message-content .content-header .content-title {
  margin: 0;
  font-size: 16px;
  color: #595959;
  font-weight: 600;
}
.message-content .message-time {
  margin-left: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #999;
  min-width: 170px;
}
.message-content .message-del {
  float: right;
  cursor: pointer;
}
.message-content .message-del .svg-icon {
  width: 16px;
  height: 16px;
  fill: #999;
}
.message-content p {
  margin: 0;
  color: #999;
}
.message-content:hover .message-del .svg-icon {
  fill: #22b8ff;
}
.page-actions {
  display: flex;
  justify-content: flex-end;
}
.page-actions .form-control {
  margin-left: 15px;
}
.page-actions.no-margin .form-control {
  margin-left: 0;
}
.page-actions .btn {
  margin-left: 15px;
}
.page-actiontab {
  display: flex;
  justify-content: flex-end;
}
.page-actiontab .btn {
  margin-left: -1px;
  padding: 8px 16px;
  border-radius: 0;
}
.page-actiontab .btn:first-child {
  border-radius: 2px 0 0 2px;
}
.page-actiontab .btn:last-child {
  border-radius: 0 2px 2px 0;
}
.page-actiontab .btn.active {
  position: relative;
  color: #fff;
  background-color: #22b8ff;
  border-color: #22b8ff;
}
.task-header {
  flex: 1;
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
}
.task-header .task-tab {
  overflow: hidden;
  font-size: 16px;
}
.task-header .task-tab .tab-item {
  float: left;
  margin-right: 36px;
  height: 44px;
  border-bottom: 3px solid #fff;
  line-height: 41px;
  cursor: pointer;
}
.task-header .task-tab .tab-item.tab-active {
  color: #22b8ff;
}
.task-header .task-tab .tab-item.active {
  color: #22b8ff;
  border-color: #22b8ff;
}
.task-header .task-search {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.task-header .task-search .search-text {
  overflow: hidden;
  border: 1px solid #dde0e5;
  border-radius: 2px;
}
.task-header .task-search .search-text input[type="text"] {
  float: left;
  border: 0 none;
  width: 196px;
  height: 32px;
  padding: 0 10px;
  font-size: 14px;
  background-color: #fff;
}
.task-header .task-search .search-text input[type="text"]:focus {
  outline: 0 none;
}
.task-header .task-search .search-btn {
  float: left;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-left: 1px solid #ddd;
}
.task-header .task-search .search-btn .svg-icon {
  fill: #d1d4d8;
}
.task-header .task-search .search-btn button {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  font-size: 100px;
  cursor: pointer;
}
.page-body {
  margin: 0 20px;
  min-height: calc(100% - 125px);
  padding: 15px;
  background-color: #fff;
}
.page-body.flex-body {
  display: flex;
  flex-direction: column;
}
.page-body.page-search {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.page-body.page-void {
  padding: 0;
  background-color: transparent;
}
.page-body .page-inner {
  border-top: 1px solid #ddd;
}
.page-body .panel-inner {
  padding: 15px;
  background-color: #fff;
}
.page-body .panel-inner + .panel-inner {
  margin-top: 20px;
}
.page-body .panel-inner .table {
  border-top-width: 0;
}
.page-body .panel-inner.table-result {
  flex: 1;
  position: relative;
}
.empty-result {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes circle {
  100% {
    transform: rotate(360deg);
  }
}
.snipper {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
}
.snipper::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -10px;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  border-left-color: transparent;
  animation: circle 0.8s infinite linear;
}
/* login -- start */
.login-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e8ec url("//static.linghemedia.com.cn/assets/images/logo-bg.jpg") no-repeat center / cover;
}
.preview .login-container {
  background-image: none;
}
.node-draft {
  color: #999;
}
.node-complete {
  color: #46cadb;
}
.my-task-split {
  height: 20px;
  background-color: #f3f6fb;
  margin: 0 -20px;
}
.words-select {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: -5px;
}
.words-select li {
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
}
.words-select li:hover {
  background-color: #f5f5f5;
}
.search-form {
  margin-bottom: 15px;
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 2px;
}
.search-form .form-group {
  display: flex;
}
.search-form .form-group + .form-group {
  margin-left: 15px;
}
.search-form .form-label {
  line-height: 32px;
  color: #8c8c8c;
  white-space: nowrap;
}
.search-form .form-content {
  flex: 1;
  min-width: 100px;
}
.search-form .form-control {
  height: 32px;
  width: 100%;
}
.search-form .autocomplete {
  width: 100%;
}
.search-form .autocomplete .form-control {
  width: 100%;
}
.search-form .autocomplete .form-control-text {
  line-height: 32px;
}
.search-form .btns {
  text-align: center;
  margin-top: 10px;
}
.search-form .btn + .btn {
  margin-left: 10px;
}
.todo-list {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
}
.todo-list a {
  margin-bottom: 15px;
  display: flex;
  padding: 10px 20px;
  color: #595959;
  background-color: #fff;
  border-radius: 2px;
}
.todo-list p,
.todo-list .apply-level {
  margin: 0 0 10px;
}
.todo-list p:last-child,
.todo-list .apply-level:last-child {
  margin-bottom: 0;
}
.todo-list .apply-desc {
  padding-left: 12px;
}
.todo-list .apply-desc .apply-time {
  margin-left: 10px;
  color: #8c8c8c;
}
.todo-list .apply-label {
  color: #8c8c8c;
}
.todo-list .apply-user {
  flex: 1;
  display: flex;
  align-items: center;
}
.todo-list .apply-level {
  display: flex;
}
.passage-time {
  display: inline-block;
  background-color: #f2f2f2;
  border-radius: 2px;
  font-size: 12px;
  line-height: 20px;
  padding: 0 6px;
  margin-left: 8px;
}
.passage-time.residue {
  color: #42c0fc;
}
.passage-time.overdue {
  color: #ff3b30;
}
.login-btn {
  float: left;
  width: 40px;
  height: 40px;
  border: 0 none;
  font-size: inherit;
  background: #ad6bad;
  color: rgba(255, 255, 255, 0.6);
}
.login-btn:focus {
  outline: none;
}
.global-util-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}
.global-util-wrap .message-box {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  line-height: 20px;
  word-break: break-word;
  overflow-wrap: break-word;
}
.global-util-wrap .close {
  position: absolute;
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAFVBMVEUAAACampqZmZmZmZmYmJiZmZmXl5dEsf2FAAAAB3RSTlMAMK9QsP9gc6yIKgAAAGRJREFUeNrNkkEOwCAIBEVY//9kb520luxVTrA7iQiMWyJmnmLOePKS1tdfUgELAl/5KiGsQNkQFCfR+sgkDYHfEfgNgd8Q+B7wT/gm/Tf9oPyo/bL8ur3gT84fbdTf2VeMS2ID6+oEVSosB2kAAAAASUVORK5CYII=") center / 14px no-repeat;
  right: 6px;
  top: 6px;
  cursor: pointer;
}
.global-util-wrap .alert-box {
  margin-bottom: 10px;
  max-width: 400px;
  padding: 20px 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  color: #fff;
}
.global-util-wrap .prompt-box {
  width: 484px;
}
.global-util-wrap .prompt-box .close {
  top: 10px;
  right: 10px;
}
.global-util-wrap .prompt-box .prompt-header {
  line-height: 49px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  font-size: 16px;
  padding-left: 10px;
}
.global-util-wrap .prompt-box .prompt-body {
  padding: 20px 30px;
}
.global-util-wrap .prompt-box .prompt-footer {
  padding-bottom: 30px;
}
.global-util-wrap .prompt-box .prompt-footer .btn + .btn {
  margin-left: 10px;
}
.global-util-wrap .confirm-box {
  width: 380px;
}
.global-util-wrap .confirm-box .confirm-body {
  padding: 34px 30px;
}
.global-util-wrap .confirm-box .confirm-footer {
  padding-bottom: 30px;
}
.global-util-wrap .confirm-box .confirm-footer .btn + .btn {
  margin-left: 10px;
}
.global-util-wrap .btn-cancel {
  color: #22b8ff;
  border-color: transparent;
}
.global-util-wrap .btn-cancel:hover {
  background: none;
}
.global-util-wrap .svg-icon {
  margin-right: 10px;
  fill: #22b8ff;
}
.tab-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.tab-container .tab-content {
  margin-left: 20px;
  flex: 1;
  padding: 15px;
  background-color: #f9f9f9;
  align-self: stretch;
}
.tab-container .tab-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ddd;
}
.tab-container .tab-nav li {
  padding: 15px 40px 15px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.tab-container .tab-nav li.active {
  padding: 15px 30px;
  color: #22b8ff;
  cursor: default;
}
.tab-container .tab-nav li.active .svg-icon {
  fill: #22b8ff;
}
.tab-container .tab-nav li + li {
  border-top: 1px solid #ddd;
}
.tab-container .tab-nav li .svg-icon {
  fill: #8c8c8c;
}
.zipped {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}
.zipped.spread {
  height: auto;
  overflow: inherit;
}
.cp-manager {
  margin: 0 auto;
  width: 640px;
}
.task-type-manager {
  display: flex;
  justify-content: space-between;
}
.task-type-list {
  width: 400px;
}
.task-type-auth {
  padding-left: 20px;
  flex: 1;
}
.task-type-auth h3 {
  margin: 0 0 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
.task-template-step {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.task-template-step a {
  margin-left: 10px;
  white-space: nowrap;
  line-height: 36px;
}
.task-assign {
  display: flex;
  justify-content: space-between;
}
.task-assign > form {
  width: 500px;
}
.task-schedule {
  flex: 1;
  padding-left: 20px;
}
.task-schedule h3 {
  margin: 0;
}
.ts-bar {
  overflow: hidden;
  border-bottom: 1px solid #ddd;
}
.ts-bar .ts-type {
  float: left;
  padding: 10px 20px;
  cursor: pointer;
}
.ts-bar .ts-type.active {
  color: #22b8ff;
  font-weight: 600;
  border-bottom: 2px solid #22b8ff;
}
.ts-content {
  padding: 10px;
}
.task-list {
  margin-bottom: 20px;
}
.task-list > a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: #595959;
  border-bottom: 1px solid #eee;
  font-size: 12px;
}
.task-list > a:hover {
  background-color: #f8f9fb;
}
.task-list .task-info {
  flex: 1;
  display: flex;
  align-items: center;
}
.task-list .task-user-name {
  font-size: 16px;
  padding-left: 10px;
  color: #8c8c8c;
  white-space: nowrap;
}
.task-list .task-desc {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  font-size: 16px;
}
.task-list .task-desc p {
  font-size: 12px;
  color: #999;
}
.task-list .task-time {
  min-width: 260px;
}
.task-list .task-status {
  min-width: 140px;
}
.task-list .task-quality {
  min-width: 150px;
}
.task-list .task-label {
  margin-right: 8px;
  color: #999;
  font-style: normal;
}
.task-list p {
  margin: 0 0 5px;
}
.task-group h3 {
  padding-left: 5px;
  font-size: 14px;
  color: #595959;
  font-weight: 400;
}
.task-group h3 span {
  color: #999;
}
.task-group ul {
  list-style: none;
}
.task-group .task-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.task-group .task-item .checkbox-item {
  margin-top: 6px;
}
.task-group .task-item .checked + pre {
  background-color: rgba(34, 184, 255, 0.1);
}
.task-group .task-item pre {
  flex: 1;
  margin: 0 0 0 15px;
  padding: 6px 10px;
  font-family: inherit;
  white-space: pre-wrap;
  background-color: #f4f5f6;
  border-radius: 3px;
}
.task-group .task-item .badge {
  margin: 5px 0 0 10px;
}
.task-form-group {
  display: flex;
  align-items: center;
}
.task-form-group label {
  padding-left: 5px;
  font-size: 14px;
  color: #595959;
  font-weight: 400;
  margin-right: 10px;
  align-self: flex-start;
}
.file-name-list {
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
}
.file-name-list li {
  position: relative;
  line-height: 28px;
  padding: 0 14px;
  display: inline-block;
  margin-right: 15px;
  border-bottom: 1px solid transparent;
  width: 220px;
  white-space: nowrap;
}
.file-name-list li:hover {
  border-bottom: 1px solid #22b8ff;
}
.file-name-list li:hover .close {
  display: block;
}
.file-name-list .close {
  display: none;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.file-name-list .close .svg-icon {
  width: 15px;
  height: 15px;
}
.file-name-list .close:hover .svg-icon {
  fill: #22b8ff;
}
.judge-control {
  margin-top: 10px;
}
.judge-control .judge-type {
  margin-right: 10px;
  display: inline-block;
  padding: 5px 10px;
  color: #fff;
  background-color: #dde0e4;
  border-radius: 2px;
  cursor: pointer;
}
.judge-control .judge-type.active {
  background-color: #faad14;
}
.judge-view {
  display: flex;
  justify-content: space-between;
}
.judge-view .judge-content {
  padding-left: 20px;
}
.judge-view h3 {
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 400;
}
.judge-view .user-code {
  margin-left: 10px;
  color: #999;
}
.judge-view .badge {
  margin-left: 10px;
}
.judge-view .judge-content {
  flex: 1;
}
.judge-view .time {
  margin: 5px 0 0;
  color: #999;
}
.hover-detail {
  color: #22b8ff;
}
.flow-setting {
  display: flex;
  justify-content: space-between;
}
.flow-setting .flow-canvas {
  max-width: 600px;
  min-width: 300px;
  overflow: auto;
}
.flow-setting .flow-node {
  flex: 1;
  padding: 40px 0 0 20px;
}
.flow-setting .node-detail {
  min-width: 400px;
}
.table-field-select {
  overflow: hidden;
  font-size: 12px;
}
.table-field-select .tf-item {
  float: left;
  margin-bottom: 10px;
  width: 33%;
  cursor: pointer;
  overflow: hidden;
}
.table-field-select .tf-binded {
  cursor: text;
  color: #999;
}
.table-field-select strong {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 30px);
  text-overflow: ellipsis;
  vertical-align: middle;
}
.table-field-select .checkbox-item {
  margin-right: 5px;
}
.table-field-select p {
  margin: 0;
  padding-left: 25px;
  color: #999;
}
.search-text {
  position: relative;
  margin-bottom: 10px;
}
.search-text::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADIklEQVR4Ae1ZA7BcUQzd2rZte1zb7aC2bdu2bds2xrVt29rtSZlmlu/dd7ftf5k5//9Vcs5Fks132GbebLNtzpw58WbMmFF62rRptaZPn94G6Am0wuPqQPEJEyZE/+tIz549OzHIdQWOgqwTcHkC3vMJ2EWi5s6dGzOoxPv16xcWRDoCbzwR9iHmAXardlDIg0BuEDhPRBTg4KxZs1JoI0+rBvLv3azobWAGUBGPs9F9wO9wkydPTojP5KR7gMcL8fupGxHPZs6cWUTHyo9xE/wqUMPlcoXy00e4Hxf7sViAL0AzK8m3cUN+MBEyePmjgfASKQK7VVI5eUqB5JwF+4zHdcz6pV2DnyFCxCsgszLySHcR4fi+CFJL8e4OF/6Pq3TeRRyb8Ypcy53YxuPgKFVVQT4GHL9gji+tWLEijMMCQyqNLWKdQ60JbVZAc7G1FS3Och1FvOJmL+9e5vCkw2LD7oYXuzDTsLMpU6bEEr1NX01VfiGL+cTwkcVKlBGXKqemSl9VxE1vdCUacUfUvOkQgJYiE487derUQkYF9GWX6bFDk1GbLQTUNHqExjFHFxwaTVT9Nkad9OK9u8ZWPYZIpXWMCmjIHDmpWgbjDlAyUZKFcBZTaspClUUWyme4tIs60FzT+Z/PYr6mOqCqEu/UUYkR5yWLucnshWrJ7wH16Tp6IWW7vmDBgihw8pw53KeSsGxdxOrT35FVnMmuMq1Z9H1gh1j9Ucq+kcH5PSbgPWUGxUdnhFikp0inCVQGKMyrIxU2BMiuYuV/DAZcAmV1TCXe0ATB5P1a5ma+dMqyognno0VAJ7AAw6tkASxEOPhpgd8PvYwd55MInZO5D8Bimr5RNpGfoSr+czIHPHFD2knn3r0I/bNR5w+SZ2liDbwSr0s8AgpDeGoa32gRwabTnU1Mp78Ay3m2cScCWKpahDweSXz9f0DgObAAvVZa7oeLoEGxLhHyaCWibvKHoFnADmA5np8EdAEK+DProXG7OxGysQuuGRCBx2tsEcEQAeI3hIj+GkKrTRRcBARMVR1DS4KgSQmwAYLi//mqbbbZ9hV25hmDvd8TGwAAAABJRU5ErkJggg==") 50% / cover no-repeat;
}
.search-text > input[type="text"] {
  padding-left: 30px;
}
.bf-content.rich-text-editor {
  height: 200px;
  background-color: #f9f9f9;
}
