@import "./variable.less";

body {
  color: @text!important;
  background-color: #f3f6fb!important;
  font-feature-settings: normal;
  font-variant: normal;

  a {
    transition: none;

    &:hover {
      color: inherit;
    }
  }
}

@primary-color: #22b8ff;@link-color: #22b8ff;@warning-color: #FFA431;@error-color: #FF2115;@text-color: #595959;@border-radius-base: 2px;@border-color-base: #d9d9d9;