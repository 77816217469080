.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
body {
  color: #595959 !important;
  background-color: #f3f6fb !important;
  font-feature-settings: normal;
  font-variant: normal;
}
body a {
  transition: none;
}
body a:hover {
  color: inherit;
}
