@import "../../styles/variable.less";

.melody {
  height: 16px;
  display: flex;
  align-items: flex-end;
  list-style: none;
  padding: 0;
  margin: 0 10px 0 0;
  overflow: hidden;
  & > span {
    float: left;
    width: 4px;
    height: 0;
    background-color: #42c0fc;
    animation: load-1 0.7s infinite;

    & + span {
      margin-left: 2px;
    }

    &:nth-child(2) {
      animation-delay: 0.1s;
    }

    &:nth-child(3) {
      animation-delay: 0.2s;
    }

    &:nth-child(4) {
      animation-delay: 0.3s;
    }

    &:nth-child(5) {
      animation-delay: 0.4s;
    }

    &:nth-child(6) {
      animation-delay: 0.5s;
    }

    &:nth-child(7) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes load-1 {
  0% {
    height: 2px;
  }
  50% {
    height: 16px;
  }
  100% {
    height: 2px;
  }
}

.ellipsis {
  position: relative;
  width: 56px;
  height: 13px;
  &::before,
  &::after,
  & > span::before,
  & > span::after {
    content: "";
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: @primary;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
  }

  &::before {
    position: absolute;
    left: 0;
    animation-name: load-2-1;
  }
  &::after {
    position: absolute;
    right: 0;
    animation-name: load-2-3;
  }

  & > span {
    position: relative;
  }

  & > span::before {
    position: absolute;
    animation-name: load-2-2;
  }

  & > span::after {
    position: absolute;
    left: 21px;
    animation-name: load-2-2;
  }
}

@keyframes load-2-1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes load-2-2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(21px);
  }
}

@keyframes load-2-3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@primary-color: #22b8ff;@link-color: #22b8ff;@warning-color: #FFA431;@error-color: #FF2115;@text-color: #595959;@border-radius-base: 2px;@border-color-base: #d9d9d9;