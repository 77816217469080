@charset "utf8";
@import "./roboto.less";

*,
*::before,
*::after {
  box-sizing: border-box;
}

@import "./variable.less";
@import "./component.less";
@import "./icon.less";

a {
  color: @primary;
  text-decoration: none;
  cursor: pointer;

  .svg-icon {
    fill: currentColor;
  }
}

.link-btn-group {
  .link-btn + .link-btn {
    margin-left: 10px;
  }
}

.link-btn {
  color: @primary;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: @primary;
  }
}

input[type="text"],
input[type="password"] {
  -webkit-appearance: none;
}

hr {
  border: 1px solid #ddd;
  border-width: 1px 0 0;
}

p {
  margin: 10px 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

html {
  min-height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: @text;
  background-color: #f3f6fb;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::placeholder,
  .ant-input::placeholder {
    color: @placeholder-color;
  }
}

.opt {
  padding: 0 10px;
  color: @primary;
  cursor: pointer;
  white-space: nowrap;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  & + .opt {
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      content: "";
      width: 1px;
      height: 14px;
      background-color: @border;
    }
  }

  &.danger {
    color: @danger;
  }
}

.add-line {
  display: block;
  height: 36px;
  border-radius: 5px;
  border: 1px dashed @border;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    border-color: @primary;
    color: @primary;
  }
}

// 添加antd之后 默认body height 为 100%，造成页面顶部被截
:root body {
  height: auto;

  .ant-btn-primary {
    text-shadow: none;
  }

  .ant-modal-close-x {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAJ1BMVEUAAACPj4+Li4uMjIyLi4uMjIyMjIyMjIyNjY2MjIyMjIyLi4uMjIx2lKwKAAAADXRSTlMAEI/vz1CQ/2Dg3/DQoQOXrwAAAJ9JREFUeNrtljEShSAMBUGUoHj/8xqLPylSrO9XFqYxE3fHAWKGokdd2ro9Abe1LdWf3TwG8+PmuifNwiDemme7hUG87b+UjICSwTwbAaQC8blEfC4Cz4bwQvm0vjh9+/QDkltAbrLEo5F4MhIPRuLZCJ4NjdeFoS+BDN5W/eCY/+bVN69eOK/KobTx4elUfpT5z9Wh9nk+u5ycs9cixwUczhTnxbYeQAAAAABJRU5ErkJggg==")
      no-repeat center / 14px;

    .anticon {
      display: none;
    }
  }

  .ant-btn {
    box-shadow: none;
  }
}

.flex-center {
  display: flex;
  align-items: center;
}

#app {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 68px;
}

.content {
  flex: 1;
  // padding: 20px 40px;
}

.clearfix {
  &::before,
  &::after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden;
  }

  &::after {
    clear: both;
  }
}

.top-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  min-height: 68px;
  max-height: 68px;
  color: rgba(0, 0, 0, 0.9);
  background: #273d55 none no-repeat 0 0;
  background-size: 100% 68px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    padding: 0 40px;
  }

  .global-search {
    flex: 1;

    form {
      display: flex;
      width: 320px;
      height: 32px;
    }

    input[type="text"] {
      flex: 1;
      margin: 0;
      height: 32px;
      padding: 0 15px;
      border: 0 none;
      font-size: 12px;
      background-color: #fff;
      border-radius: 16px 0 0 16px;

      &:focus {
        outline: none;
      }
    }

    .search-btn {
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      background-color: @primary;
      border-radius: 0 16px 16px 0;

      .svg-icon {
        fill: #fff;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        font-size: 100px;
        cursor: pointer;
      }
    }
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;

    .svg-icon {
      fill: #fff;
    }

    .tools {
      display: flex;

      a {
        margin-right: 20px;
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
      }

      .has-unread {
        &::before {
          position: absolute;
          top: -3px;
          right: -1px;
          content: "";
          width: 10px;
          height: 10px;
          background: @danger;
          border-radius: 50%;
        }
      }
    }
  }

  .user-ctrl {
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;
    height: 68px;

    &:hover {
      .header-dropdown {
        display: block;
      }
    }
  }

  .header-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    right: -10px;
    margin: -5px 0 0;
    width: 140px;
    padding: 5px 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    list-style: none;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: -9px;
      right: 15px;
      border: 10px solid transparent;
      border-top-width: 0;
      border-bottom-color: #fff;
    }

    &::before {
      border-bottom-color: #ddd;
      top: -10px;
    }

    a {
      display: block;
      height: 40px;
      padding: 0 15px;
      line-height: 40px;
      color: @text-grey;

      .svg-icon {
        fill: @text-grey;
      }

      &:hover {
        background-color: #f3f3f3;
        color: @text-grey;
      }
    }

    li + li {
      a {
        border-top: 1px solid #ddd;
      }
    }
  }

  .logo-path {
    position: relative;
    display: block;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.2em;

    &.only-img {
      margin-right: 48px;
      font-size: 0;
    }

    .logo-img {
      height: 50px;
    }

    .env {
      position: absolute;
      white-space: nowrap;
      left: 6px;
      top: 0;
      line-height: 14px;
      font-size: 12px;
      padding: 0 7px;
      border-radius: 10px;
      color: #fff;

      &.dev {
        background: #76bd09;
      }

      &.test {
        background: #d21e1e;
      }
    }
  }

  .avatar {
    width: 34px;
    height: 34px;
  }
}

.preview .top-header {
  .env {
    display: none;
  }
}

.wrap {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.navbar {
  position: relative;
  z-index: 10;
  min-width: 240px;
  max-width: 240px;
  background-color: #fff;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);

  .nav-menu {
    padding: 16px 15px 0 15px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  // .folder-name {
  //   margin-top: 10px;
  //   padding-left: 15px;
  //   border-top: 1px solid #ddd;
  //   color: @text-light;
  //   line-height: 42px;
  //   font-size: 12px;
  // }

  .svg-icon {
    width: 18px;
    height: 18px;
  }

  .menu {
    li {
      + li {
        margin-top: 10px;
      }
    }

    a {
      display: block;
      padding: 0 40px 0 20px;
      line-height: 42px;
      border-radius: 3px;
      font-size: 14px;
      color: @text;

      &.folder-menu {
        font-weight: bold;
      }

      &:hover,
      &.active {
        background-color: @barDark;
        color: #fff;

        .svg-icon {
          fill: #fff;
        }
      }

      .svg-icon {
        fill: @primary;
      }
    }

    &.single-menu {
      a.active {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .svg-icon {
      margin-right: 5px;
      vertical-align: middle;
    }
  }
}

.auth-menu {
  margin: 15px -15px 0;

  h3 {
    margin: 0 25px;
    padding: 15px 0 5px;
    font-size: 12px;
    font-weight: 400;
    color: @text-light;
    border-top: 2px solid #e3e5e8;
  }
}

.menu-module {
  > li {
    &::after {
      content: "";
      display: block;
      height: 10px;
      background: transparent;
    }

    position: relative;
    padding: 0 15px;

    &.hover {
      .menu-apps {
        display: block;
        padding: 20px 30px;
      }

      .module-name {
        background-color: @barDark;
        color: #fff;
        border-radius: 3px;

        .svg-icon {
          fill: #fff;
        }
      }
    }
  }

  .module-name {
    position: relative;
    padding-left: 20px;
    line-height: 42px;

    .svg-icon {
      fill: @primary;
      margin-right: 5px;
    }

    .svg-icon-right {
      position: absolute;
      right: 0;
      top: 12px;
      fill: #a0a8b5;
    }
  }
}

.menu-apps {
  display: none;
  position: fixed;
  top: 68px;
  left: 240px;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 20px 30px;
  background-color: #f3f6fb;
  list-style: none;
  overflow: auto;

  > li {
    padding-bottom: 20px;
  }

  .app-name {
    font-size: 18px;
    margin-bottom: 20px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
    width: 1px;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
  }
}

.menu-items {
  margin: 0;
  padding: 0;
  list-style: none;

  &::after {
    content: "";
    display: table;
    clear: both;
  }

  > li {
    position: relative;
    float: left;
    display: flex;
    margin: 0 -1px -1px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 130px;
    background-color: #fff;
    border: 1px solid #ddd;

    &:hover {
      .menu-link {
        display: flex;
      }
    }
  }

  p {
    margin: 15px 0 0;
  }

  .svg-icon {
    width: 42px;
    height: 42px;
    fill: @primary;
  }

  .menu-link {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    background: #fff;
    text-align: center;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      font-size: 12px;
      color: @text;

      &:hover {
        background-color: @barDark;
        color: #fff;
      }
    }
  }
}

.header-fixed {
  &.no-breadcrumb {
    .content {
      padding-top: 60px;
    }
  }

  .content {
    padding-top: 90px;
  }

  .page-header {
    position: fixed;
    z-index: 10;
    margin-top: -90px;
    width: 100%;

    &.no-breadcrumb {
      margin-top: -60px;
    }
  }

  .message-header {
    position: fixed;
    z-index: 10;
    margin-top: -90px;
    width: 100%;
  }
}

.message-header {
  border-bottom: 1px solid #dde0e5;
  background-color: #fff;
  margin-bottom: 20px;

  .container {
    margin: 0 auto;
    width: 920px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    color: @primary;
  }

  .message-sort {
    width: 160px;
  }

  .form-control {
    height: 34px;
  }

  .message-status {
    float: left;
    margin: 0 18px;
    line-height: 57px;
    font-size: 17px;
    cursor: pointer;
    border-bottom: 3px solid #fff;

    &.active {
      line-height: 57px;
      color: @primary;
      border-bottom-color: @primary;
    }
  }
}

.page-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  height: 90px;
  padding: 40px 40px 0 20px;
  border-bottom: 1px solid #dde0e5;
  background-color: #fff;

  &.no-breadcrumb {
    height: 60px;
    padding-top: 0;
  }

  &.lucency {
    border-bottom-color: transparent;
    background-color: transparent;

    h1 {
      color: #595959;
    }

    .breadcrumb {
      background-color: transparent;
    }
  }

  .breadcrumb {
    position: absolute;
    top: 21px;
    left: 20px;
  }

  h1,
  h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    color: @primary;
  }

  // a {
  //   color: @text-grey;
  // }

  .svg-icon {
    fill: @text-grey;
  }
}

.message-container {
  padding: 0 5px;
}

.message-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px;
  border-bottom: 1px solid #eee;

  .svg-icon {
    margin-right: 5px;
    fill: @primary;
    width: 20px;
    height: 20px;
  }

  .status-control {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 20px;
    }
  }
}

.message-list {
  margin-bottom: 20px;
}

.message-item {
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 10px;
  border-bottom: 1px solid #eee;
  border-radius: 1px;
  cursor: pointer;

  &:hover {
    background-color: rgba(@primary, 0.05);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);

    p {
      color: @text;
    }
  }
}

.message-type {
  margin: 0 15px;
  width: 44px;
  height: 44px;
  border-radius: 22px;
}

.message-content {
  flex: 1;

  .content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content-header-left {
      display: flex;
      align-items: center;
    }

    .content-title {
      margin: 0;
      font-size: 16px;
      color: @text;
      font-weight: 600;
    }
  }

  .message-time {
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
    color: @text-light;
    min-width: 170px;
  }

  .message-del {
    float: right;
    cursor: pointer;

    .svg-icon {
      width: 16px;
      height: 16px;
      fill: @text-light;
    }
  }

  p {
    margin: 0;
    color: @text-light;
  }

  &:hover {
    .message-del .svg-icon {
      fill: @primary;
    }
  }
}

.page-actions {
  display: flex;
  justify-content: flex-end;

  .form-control {
    margin-left: 15px;
  }

  &.no-margin {
    .form-control {
      margin-left: 0;
    }
  }

  .btn {
    margin-left: 15px;
  }
}

.page-actiontab {
  display: flex;
  justify-content: flex-end;

  .btn {
    margin-left: -1px;
    padding: 8px 16px;
    border-radius: 0;

    &:first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }

    &.active {
      position: relative;
      color: #fff;
      background-color: @primary;
      border-color: @primary;
    }
  }
}

.task-header {
  flex: 1;
  align-self: flex-end;
  display: flex;
  justify-content: space-between;

  .task-tab {
    overflow: hidden;
    font-size: 16px;

    .tab-item {
      float: left;
      margin-right: 36px;
      height: 44px;
      border-bottom: 3px solid #fff;
      line-height: 41px;
      cursor: pointer;

      &.tab-active {
        color: @primary;
      }

      &.active {
        color: @primary;
        border-color: @primary;
      }
    }
  }

  .task-search {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .search-text {
      overflow: hidden;
      border: 1px solid #dde0e5;
      border-radius: 2px;

      input[type="text"] {
        float: left;
        border: 0 none;
        width: 196px;
        height: 32px;
        padding: 0 10px;
        font-size: 14px;
        background-color: #fff;

        &:focus {
          outline: 0 none;
        }
      }
    }

    .search-btn {
      float: left;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-left: 1px solid #ddd;

      .svg-icon {
        fill: #d1d4d8;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        font-size: 100px;
        cursor: pointer;
      }
    }
  }
}

.page-body {
  margin: 0 20px;
  min-height: calc(100% - 125px);
  padding: 15px;
  background-color: #fff;

  &.flex-body {
    display: flex;
    flex-direction: column;
  }

  &.page-search {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.page-void {
    padding: 0;
    background-color: transparent;
  }

  .page-inner {
    border-top: 1px solid #ddd;
  }

  .panel-inner {
    padding: 15px;
    background-color: #fff;

    + .panel-inner {
      margin-top: 20px;
    }

    .table {
      border-top-width: 0;
    }

    &.table-result {
      flex: 1;
      position: relative;
      // 表格头部下边框
      // &.has-result::before {
      //   content: "";
      //   position: absolute;
      //   top: 48px;
      //   left: 0;
      //   right: 0;
      //   border-bottom: 1px solid #ddd;
      // }
    }
  }
}

.empty-result {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

// footer {
//   // border-top: 1px solid #ddd;
//   min-height: 60px;
//   max-height: 60px;
//   line-height: 60px;
//   text-align: center;
//   color: rgba(255, 255, 255, 0.8);
//   background-color: #424242;
// }

@keyframes circle {
  100% {
    transform: rotate(360deg);
  }
}

.snipper {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    border-left-color: transparent;
    animation: circle 0.8s infinite linear;
  }
}

/* login -- start */
.login-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e8ec url("//static.linghemedia.com.cn/assets/images/logo-bg.jpg") no-repeat center /
    cover;
}

.preview .login-container {
  background-image: none;
}

.node-draft {
  color: @text-light;
}

.node-complete {
  color: @strong;
}

.my-task-split {
  height: 20px;
  background-color: #f3f6fb;
  margin: 0 -20px;
}

.words-select {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: -5px;

  li {
    padding: 5px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.search-form {
  margin-bottom: 15px;
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 2px;

  .form-group {
    display: flex;

    & + .form-group {
      margin-left: 15px;
    }
  }

  .form-label {
    line-height: 32px;
    color: @text-grey;
    white-space: nowrap;
  }

  .form-content {
    flex: 1;
    min-width: 100px;
  }

  .form-control {
    height: 32px;
    width: 100%;
  }

  .autocomplete {
    width: 100%;

    .form-control {
      width: 100%;
    }

    .form-control-text {
      line-height: 32px;
    }
  }

  .btns {
    text-align: center;
    margin-top: 10px;
  }

  .btn + .btn {
    margin-left: 10px;
  }
}

.todo-list {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;

  a {
    margin-bottom: 15px;
    display: flex;
    padding: 10px 20px;
    color: @text;
    background-color: #fff;
    border-radius: 2px;
  }

  p,
  .apply-level {
    &:last-child {
      margin-bottom: 0;
    }

    margin: 0 0 10px;
  }

  .apply-desc {
    padding-left: 12px;

    .apply-time {
      margin-left: 10px;
      color: #8c8c8c;
    }
  }

  .apply-label {
    color: #8c8c8c;
  }

  .apply-user {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .apply-level {
    display: flex;
  }
}

.passage-time {
  display: inline-block;
  background-color: #f2f2f2;
  border-radius: 2px;
  font-size: 12px;
  line-height: 20px;
  padding: 0 6px;
  margin-left: 8px;

  &.residue {
    color: #42c0fc;
  }

  &.overdue {
    color: #ff3b30;
  }
}

.login-btn {
  float: left;
  width: 40px;
  height: 40px;
  border: 0 none;
  font-size: inherit;
  background: #ad6bad;
  color: rgba(255, 255, 255, 0.6);

  &:focus {
    outline: none;
  }
}

.global-util-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);

  .message-box {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;
    line-height: 20px;
    .word-break;
  }

  .close {
    position: absolute;
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAFVBMVEUAAACampqZmZmZmZmYmJiZmZmXl5dEsf2FAAAAB3RSTlMAMK9QsP9gc6yIKgAAAGRJREFUeNrNkkEOwCAIBEVY//9kb520luxVTrA7iQiMWyJmnmLOePKS1tdfUgELAl/5KiGsQNkQFCfR+sgkDYHfEfgNgd8Q+B7wT/gm/Tf9oPyo/bL8ur3gT84fbdTf2VeMS2ID6+oEVSosB2kAAAAASUVORK5CYII=")
      center / 14px no-repeat;
    right: 6px;
    top: 6px;
    cursor: pointer;
  }

  .alert-box {
    margin-bottom: 10px;
    max-width: 400px;
    padding: 20px 20px;
    background-color: rgba(0, 0, 0, 0.6);
    border: 0 none;
    color: #fff;
  }

  .prompt-box {
    width: 484px;

    .close {
      top: 10px;
      right: 10px;
    }

    .prompt-header {
      line-height: 49px;
      border-bottom: 1px solid #ddd;
      text-align: left;
      font-size: 16px;
      padding-left: 10px;
    }

    .prompt-body {
      padding: 20px 30px;
    }

    .prompt-footer {
      // border-top: 1px solid #ddd;
      padding-bottom: 30px;

      .btn + .btn {
        margin-left: 10px;
      }
    }
  }

  .confirm-box {
    width: 380px;

    .confirm-body {
      padding: 34px 30px;
    }

    .confirm-footer {
      padding-bottom: 30px;

      .btn + .btn {
        margin-left: 10px;
      }
    }
  }

  .btn-cancel {
    color: @primary;
    border-color: transparent;

    &:hover {
      background: none;
    }
  }

  .svg-icon {
    margin-right: 10px;
    fill: @primary;
  }
}

.tab-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .tab-content {
    margin-left: 20px;
    flex: 1;
    padding: 15px;
    background-color: #f9f9f9;
    align-self: stretch;
  }

  .tab-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid #ddd;

    li {
      padding: 15px 40px 15px 20px;
      cursor: pointer;
      transition: all 0.3s ease;

      &.active {
        padding: 15px 30px;
        color: @primary;
        cursor: default;

        .svg-icon {
          fill: @primary;
        }
      }

      + li {
        border-top: 1px solid #ddd;
      }

      .svg-icon {
        fill: @text-grey;
      }
    }
  }
}

.zipped {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;

  &.spread {
    height: auto;
    overflow: inherit;
  }
}

.cp-manager {
  margin: 0 auto;
  width: 640px;
}

.task-type-manager {
  display: flex;
  justify-content: space-between;
}

.task-type-list {
  width: 400px;
}

.task-type-auth {
  padding-left: 20px;
  flex: 1;

  h3 {
    margin: 0 0 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
}

.task-template-step {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  a {
    margin-left: 10px;
    white-space: nowrap;
    line-height: 36px;
  }
}

.task-assign {
  display: flex;
  justify-content: space-between;

  > form {
    width: 500px;
  }
}

.task-schedule {
  flex: 1;
  padding-left: 20px;

  h3 {
    margin: 0;
  }
}

.ts-bar {
  overflow: hidden;
  border-bottom: 1px solid #ddd;

  .ts-type {
    float: left;
    padding: 10px 20px;
    cursor: pointer;

    &.active {
      color: @primary;
      font-weight: 600;
      border-bottom: 2px solid @primary;
    }
  }
}

.ts-content {
  padding: 10px;
}

.task-list {
  margin-bottom: 20px;

  > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: @text;
    border-bottom: 1px solid #eee;
    font-size: 12px;

    &:hover {
      background-color: #f8f9fb;
    }
  }

  .task-info {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .task-user-name {
    font-size: 16px;
    padding-left: 10px;
    color: @text-grey;
    white-space: nowrap;
  }

  .task-desc {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    font-size: 16px;

    p {
      font-size: 12px;
      color: @text-light;
    }
  }

  .task-time {
    min-width: 260px;
  }

  .task-status {
    min-width: 140px;
  }

  .task-quality {
    min-width: 150px;
  }

  .task-label {
    margin-right: 8px;
    color: @text-light;
    font-style: normal;
  }

  p {
    margin: 0 0 5px;
  }
}

.task-group {
  h3 {
    padding-left: 5px;
    font-size: 14px;
    color: @text;
    font-weight: 400;

    span {
      color: @text-light;
    }
  }

  ul {
    list-style: none;
  }

  .task-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .checkbox-item {
      margin-top: 6px;
    }

    .checked + pre {
      background-color: rgba(@primary, 0.1);
    }

    pre {
      flex: 1;
      margin: 0 0 0 15px;
      padding: 6px 10px;
      font-family: inherit;
      white-space: pre-wrap;
      background-color: #f4f5f6;
      border-radius: 3px;
    }

    .badge {
      margin: 5px 0 0 10px;
    }
  }
}

.task-form-group {
  display: flex;
  align-items: center;

  label {
    padding-left: 5px;
    font-size: 14px;
    color: @text;
    font-weight: 400;
    margin-right: 10px;
    align-self: flex-start;
  }
}

.file-name-list {
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    line-height: 28px;
    padding: 0 14px;
    display: inline-block;
    margin-right: 15px;
    border-bottom: 1px solid transparent;
    width: 220px;
    white-space: nowrap;

    &:hover {
      border-bottom: 1px solid @primary;

      .close {
        display: block;
      }
    }
  }

  .close {
    display: none;
    position: absolute;
    right: -8px;
    top: -8px;
    width: 15px;
    height: 15px;
    cursor: pointer;

    .svg-icon {
      width: 15px;
      height: 15px;
    }

    &:hover {
      .svg-icon {
        fill: @primary;
      }
    }
  }
}

.judge-control {
  margin-top: 10px;

  .judge-type {
    margin-right: 10px;
    display: inline-block;
    padding: 5px 10px;
    color: #fff;
    background-color: #dde0e4;
    border-radius: 2px;
    cursor: pointer;

    &.active {
      background-color: @warn;
    }
  }
}

.judge-view {
  display: flex;
  justify-content: space-between;

  .judge-content {
    padding-left: 20px;
  }

  h3 {
    display: flex;
    align-items: center;
    margin: 0;
    font-weight: 400;
  }

  .user-code {
    margin-left: 10px;
    color: @text-light;
  }

  .badge {
    margin-left: 10px;
  }

  .judge-content {
    flex: 1;
  }

  .time {
    margin: 5px 0 0;
    color: @text-light;
  }
}

.hover-detail {
  color: @primary;
}

.flow-setting {
  display: flex;
  justify-content: space-between;

  .flow-canvas {
    max-width: 600px;
    min-width: 300px;
    overflow: auto;
  }

  .flow-node {
    flex: 1;
    padding: 40px 0 0 20px;
  }

  .node-detail {
    min-width: 400px;
  }
}

.table-field-select {
  overflow: hidden;
  font-size: 12px;

  .tf-item {
    float: left;
    margin-bottom: 10px;
    width: 33%;
    cursor: pointer;
    overflow: hidden;
  }

  .tf-binded {
    cursor: text;
    color: @text-light;
  }

  strong {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 30px);
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .checkbox-item {
    margin-right: 5px;
  }

  p {
    margin: 0;
    padding-left: 25px;
    color: @text-light;
  }
}

.search-text {
  position: relative;
  margin-bottom: 10px;
  &::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 7px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADIklEQVR4Ae1ZA7BcUQzd2rZte1zb7aC2bdu2bds2xrVt29rtSZlmlu/dd7ftf5k5//9Vcs5Fks132GbebLNtzpw58WbMmFF62rRptaZPn94G6Am0wuPqQPEJEyZE/+tIz549OzHIdQWOgqwTcHkC3vMJ2EWi5s6dGzOoxPv16xcWRDoCbzwR9iHmAXardlDIg0BuEDhPRBTg4KxZs1JoI0+rBvLv3azobWAGUBGPs9F9wO9wkydPTojP5KR7gMcL8fupGxHPZs6cWUTHyo9xE/wqUMPlcoXy00e4Hxf7sViAL0AzK8m3cUN+MBEyePmjgfASKQK7VVI5eUqB5JwF+4zHdcz6pV2DnyFCxCsgszLySHcR4fi+CFJL8e4OF/6Pq3TeRRyb8Ypcy53YxuPgKFVVQT4GHL9gji+tWLEijMMCQyqNLWKdQ60JbVZAc7G1FS3Och1FvOJmL+9e5vCkw2LD7oYXuzDTsLMpU6bEEr1NX01VfiGL+cTwkcVKlBGXKqemSl9VxE1vdCUacUfUvOkQgJYiE487derUQkYF9GWX6bFDk1GbLQTUNHqExjFHFxwaTVT9Nkad9OK9u8ZWPYZIpXWMCmjIHDmpWgbjDlAyUZKFcBZTaspClUUWyme4tIs60FzT+Z/PYr6mOqCqEu/UUYkR5yWLucnshWrJ7wH16Tp6IWW7vmDBgihw8pw53KeSsGxdxOrT35FVnMmuMq1Z9H1gh1j9Ucq+kcH5PSbgPWUGxUdnhFikp0inCVQGKMyrIxU2BMiuYuV/DAZcAmV1TCXe0ATB5P1a5ma+dMqyognno0VAJ7AAw6tkASxEOPhpgd8PvYwd55MInZO5D8Bimr5RNpGfoSr+czIHPHFD2knn3r0I/bNR5w+SZ2liDbwSr0s8AgpDeGoa32gRwabTnU1Mp78Ay3m2cScCWKpahDweSXz9f0DgObAAvVZa7oeLoEGxLhHyaCWibvKHoFnADmA5np8EdAEK+DProXG7OxGysQuuGRCBx2tsEcEQAeI3hIj+GkKrTRRcBARMVR1DS4KgSQmwAYLi//mqbbbZ9hV25hmDvd8TGwAAAABJRU5ErkJggg==")
      50% / cover no-repeat;
  }
  & > input[type="text"] {
    padding-left: 30px;
  }
}

.bf-content.rich-text-editor {
  height: 200px;
  background-color: #f9f9f9;
}

@primary-color: #22b8ff;@link-color: #22b8ff;@warning-color: #FFA431;@error-color: #FF2115;@text-color: #595959;@border-radius-base: 2px;@border-color-base: #d9d9d9;