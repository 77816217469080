@import "./variable.less";

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 60px;

  .tab-item {
    margin: 0 20px;
    font-size: 16px;
    line-height: 58px;
    border-bottom: 2px solid transparent;

    &.active {
      color: @primary;
      border-color: @primary;
    }
  }
}

.avatar {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #fff none no-repeat center / cover;
  vertical-align: middle;
  border-radius: 50%;

  &.show-title {
    &::before {
      display: none;
      content: attr(data-title);
      position: absolute;
      bottom: 100%;
      top: auto;
      left: 50%;
      z-index: 10;
      transform: translate(-50%, -6px);
      padding: 4px 8px;
      color: #fff;
      font-size: 12px;
      white-space: nowrap;
      border-radius: 3px;
      background-color: rgba(59, 64, 77, 0.9);
    }

    &::after {
      display: none;
      content: "";
      position: absolute;
      bottom: 100%;
      top: auto;
      left: 50%;
      z-index: 10;
      transform: translate(-50%, -2px);
      width: 0;
      height: 0;
      overflow: hidden;
      border: 4px solid transparent;
      border-bottom-width: 0;
      border-top-color: rgba(59, 64, 77, 0.9);
    }

    &:hover {

      &::after,
      &::before {
        display: block;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
  }
}

.badge {
  display: inline-block;
  margin-right: 5px;
  padding: 0 5px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  background-color: #e9e9e9;
  color: @text;
  border-radius: 3px;

  &.badge-xl {
    padding: 0 10px;
  }

  &.badge-primary {
    background-color: @primary;
    color: #fff;

    &.badge-light {
      color: @primary;
      background-color: rgba(@primary, 0.2);
    }
  }

  &.badge-warn {
    background-color: @warn;
    color: #fff;

    &.badge-light {
      color: @warn;
      background-color: rgba(@warn, 0.2);
    }
  }

  &.badge-strong {
    background-color: @strong;
    color: #fff;

    &.badge-light {
      color: @strong;
      background-color: rgba(@strong, 0.2);
    }
  }

  &.badge-danger {
    background-color: @danger;
    color: #fff;

    &.badge-light {
      color: @danger;
      background-color: rgba(@danger, 0.2);
    }
  }

  &.badge-light {
    color: #818794;
  }

  &.badge-reverse {
    background-color: #dde0e4;
    color: #fff;
  }
}

.btn {
  display: inline-block;
  line-height: 1;
  // padding: 8px 20px;
  padding: 12px 28px;
  // font-size: 12px;
  font-size: 14px;
  color: #333;
  border-radius: 0.2em;
  background: #fff none repeat-y;
  border: 1px solid #dddddd;
  text-align: center;
  // box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .1);
  transition: background 0.15s ease-in-out;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;

  .svg-icon {
    margin-right: 5px;
    width: 14px;
    height: 14px;
    fill: rgba(0, 0, 0, 0.6);
    vertical-align: bottom;
  }

  &.btn-primary {
    color: #fff;
    background-color: @primary;
    border-color: transparent;

    &:active {
      color: rgba(255, 255, 255, 0.6);
    }

    .svg-icon {
      fill: #fff;
    }
  }

  &.btn-strong {
    color: #fff;
    background-color: @strong;
    border-color: transparent;

    &:active {
      color: rgba(255, 255, 255, 0.6);
    }

    .svg-icon {
      fill: #fff;
    }
  }

  &.btn-danger {
    color: rgba(255, 255, 255, 0.8);
    background-color: @danger;
    border-color: transparent;

    &:active {
      color: rgba(255, 255, 255, 0.6);
    }

    .svg-icon {
      fill: #fff;
    }
  }

  &.btn-warn {
    color: rgba(255, 255, 255, 0.9);
    background-color: @warn;
    border-color: transparent;

    &:active {
      color: rgba(255, 255, 255, 0.6);
    }

    .svg-icon {
      fill: #fff;
    }
  }

  &.btn-xs {
    font-size: 12px;
    padding: 5px 10px;
  }

  &.btn-sm {
    font-size: 12px;
    padding: 9px 20px;
  }

  &.btn-xl {
    padding: 15px 40px;
    font-size: 16px;
  }

  &.btn-long {
    padding-left: 50px;
    padding-right: 50px;
  }

  &.btn-block {
    display: block;
    margin: 15px 0;
    width: 100%;
  }

  &.btn-circle {
    border-radius: 20px;
  }

  &.disabled,
  &[disabled] {
    // color: rgba(255, 255, 255, .5);
    // color: rgba(255, 255, 255, .8);
    // color: #fff;
    background-color: #d4d4d4;
    cursor: not-allowed;
    color: #fff;

    &:hover {
      background-image: none;
    }
  }

  &:hover {
    // border-color: rgba(0, 0, 0, .2);
    background-image: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
  }

  &:focus {
    outline: 0 none;
  }

  &:active {
    outline: 0 none;
    background-image: linear-gradient(rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08));
  }

  &.btn-loading {
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.3));
    cursor: default;
  }
}

.icon-btn {
  display: inline-block;
  font-size: 0;
  padding: 5px;
  cursor: pointer;
  vertical-align: middle;

  &[data-title] {
    .svg-icon {
      fill: #d1d4d8;
    }

    &:hover {
      .svg-icon {
        fill: @primary;
      }
    }
  }

  +.icon-btn {
    margin-left: 5px;
  }

  .svg-icon {
    width: 12px;
    height: 12px;
    fill: rgba(0, 0, 0, 0.6);
    vertical-align: bottom;
  }

  &.icon-btn-primary {
    .svg-icon {
      fill: @primary;
    }
  }

  &.icon-btn-strong {
    .svg-icon {
      fill: @strong;
    }
  }

  &.icon-btn-danger {
    .svg-icon {
      fill: @danger;
    }
  }

  &.icon-btn-warn {
    .svg-icon {
      fill: @warn;
    }
  }
}

.upload-progress {
  margin-bottom: 10px;
  height: 1em;
  transition: width 0.2s;
  background-color: rgba(66, 192, 252, 0.5);
}

.uploader {
  border: 1px solid #dde0e5;
  border-radius: 3px;
  padding: 5px 10px 0;

  .file-upload {
    margin-bottom: 5px;
    line-height: 20px;
    color: @primary;

    .svg-icon {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      fill: @primary;
    }
  }

  .image-list {
    margin: 0;
    padding: 0;
    overflow: hidden;
    list-style: none;

    li {
      float: left;
      margin-right: 10px;
      margin-bottom: 10px;
      width: 100px;
      height: 100px;
      padding: 5px;
      position: relative;
      border: 1px solid #ddd;
      background-color: #fff;
      border-radius: 2px;
      text-align: center;

      &:hover {
        .image-remove {
          display: block;
        }
      }
    }

    img {
      max-width: 90px;
      max-height: 90px;
    }
  }

  .image-log {
    position: absolute;
    bottom: 5px;
    right: 5px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    font-size: 0;

    &:hover {
      .svg-icon {
        fill: #fff;
      }
    }

    .svg-icon {
      width: 16px;
      height: 16px;
      fill: #fff;
    }
  }

  .image-remove {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    font-size: 0;

    &:hover {
      .svg-icon {
        fill: #fff;
      }
    }

    .svg-icon {
      width: 16px;
      height: 16px;
      fill: #fff;
    }
  }

  .file-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 5px;

      &:hover {
        .file-edit-btn {
          display: inline;
        }
      }
    }

    a {
      margin: 0 5px;
    }
  }

  .file-size {
    color: @text-light;
  }

  .svg-icon {
    width: 14px;
    height: 14px;
    fill: #d1d4d9;
  }

  .file-edit-btn {
    display: none;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
      .svg-icon {
        fill: @primary;
      }
    }
  }
}

.toggle-btn {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 26px;
  padding: 0 8px;
  text-align: right;
  border-radius: 13px;
  border: 0;
  background-color: #e0e4ee;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;

  &.small {
    width: @fc-height;
    height: 20px;

    &.active {
      &::before {
        left: calc(100% - 18px);
      }
    }

    &::before {
      top: 2px;
      left: 2px;
      width: 16px;
      height: 16px;
    }

    &::after {
      line-height: 20px;
    }
  }

  &.readonly {
    cursor: not-allowed;
  }

  &::after {
    content: attr(data-no);
    line-height: 26px;
    font-size: 12px;
    text-align: right;
    color: #fff;
  }

  &.active {
    text-align: left;
    border-color: @primary;
    background: @primary linear-gradient(246deg,
        #4293fc,
        #4ea9f9 34%,
        #4ea9f9 34%,
        #58bdf7 64%,
        #58bdf7 64%,
        #5ac1f7 70%,
        #64d5f5) repeat 0 0;
    box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.1);

    &::before {
      left: calc(100% - 23px);
    }

    &::after {
      content: attr(data-yes);
      color: #fff;
    }
  }

  &::before {
    position: absolute;
    content: "";
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    // border: 1px solid rgba(0, 0, 0, .05);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
    transition: left 0.3s ease;
  }
}

.btn-group {
  .btn {
    +.btn {
      margin-left: 10px;
    }
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}

.alert {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  .svg-icon {
    margin-right: 10px;
    fill: @primary;
  }
}

.time-selector {
  display: flex;
  align-items: center;

  .form-control {
    width: 50px;
  }

  .time-sep {
    margin: 0 5px;
  }
}

.dropdown-control {
  position: relative;

  .dropdown-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: @fc-height;
    padding: 0 10px;
    border: 1px solid #d0d0d0;
    background-color: #fff;
    border-radius: 3px;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      line-height: 0;
      border: 5px solid transparent;
      border-top-color: @text-grey;
      border-bottom-width: 0;
    }
  }

  .dropdown-selected {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: @text-grey;

    &.has-value {
      color: @text;
    }
  }

  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: -1px;
    width: 100%;
    max-height: 180px;
    overflow: auto;
    border: 1px solid #d0d0d0;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  }

  .dropdown-option {
    height: 36px;
    padding: 0 10px;
    line-height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.dropdown-option-empty {
      color: @text-grey;
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }
}

.form-control {
  width: 100%;
  padding: 0 10px;
  height: @fc-height;
  border: 1px solid #d9d9d9;
  color: @text;
  background-color: #fff;
  font-size: inherit;
  border-radius: 3px;
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    margin 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);

  &.form-sm {
    height: 30px;
  }

  &.form-control-text {
    line-height: @fc-height;

    &.form-sm {
      line-height: 30px;
    }
  }

  &:focus {
    outline: 0 none;
    border-color: @primary;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  }

  &.form-error {
    border-color: @danger;
    background-color: rgba(@danger, 0.2);
  }

  &.form-warn {
    border-color: @warn;
    background-color: rgba(@warn, 0.2);
  }

  &.form-default {
    background-color: rgba(0, 0, 0, 0.02);
  }

  &[readonly] {
    background-color: #f0f0f0;

    &:focus {
      border-color: #d0d0d0;
    }
  }
}

textarea.form-control {
  height: 80px;
  padding: 5px 10px;
  resize: vertical;
}

.block-radio {
  padding-left: 5px;

  .radio-item {
    display: block;
  }
}

.radio-item {
  height: 36px;
  line-height: 36px;
  position: relative;
  margin-right: 10px;
  padding-left: 25px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;

  &::before {
    position: absolute;
    left: 0;
    top: 8px;
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #ccc;
    background-color: #fff;
    // box-shadow: 0 3px 10px rgba(0, 0, 0, .05);
  }

  &::after {
    display: none;
    position: absolute;
    left: 3px;
    top: 11px;
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: @primary;
  }

  &.checked {
    &::before {
      border-color: @primary;
    }

    &::after {
      display: block;
    }
  }

  &.disabled {
    cursor: not-allowed;
    color: #c0c0c0;
    background-color: transparent;

    &::before {
      background-color: transparent;
    }
  }

  &.radio-simple {
    font-size: 0;
    padding-left: 20px;
    margin-right: 0;
  }

  &.radio-sm {
    padding-left: 22px;

    &::before {
      width: 14px;
      height: 14px;
      top: 11px;
      left: 3px;
    }

    &::after {
      left: 7px;
      top: 15px;
      width: 6px;
      height: 6px;
    }
  }
}

.checkbox-item {
  position: relative;
  height: 36px;
  line-height: 36px;
  margin-right: 10px;
  padding-left: 20px;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    &::before {
      border-color: @primary;
    }
  }

  &.checkbox-simple {
    width: 16px;
    height: 16px;
    font-size: 0;
    padding-left: 16px;
    line-height: 16px;
    margin-right: 0;

    &::before {
      top: 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    border-radius: 2px;
    vertical-align: middle;
    // box-shadow: 0 3px 10px rgba(0, 0, 0, .05);
    background-color: #fff;
  }

  &.checked {
    &::before {
      border-color: @primary;
      background: @primary url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAjUlEQVR4AWMYMWAUjIL///8rAHE/EOcDsQC9LTcA4vf/EWAB/S1HBRfoazkmaBhIyxeMWj5q+ajl+AxaD8TngTiAfpYjDNuAZkgCXYMdVDxiMSyB9pYjDHX4jx0k0C3BgSzD5QjKLafcEfWELKe5I/BaTn9HICynsyPo36JJoI/lhLPoBHBuGG5gFIwCAHaUtjpX3RYzAAAAAElFTkSuQmCC) no-repeat center / 75%;
    }
  }

  &.readonly {
    cursor: default;

    &:hover {
      &::before {
        border-color: #ccc;
      }
    }

    &::before {
      background-color: #f3f4f5;
    }

    &.checked {
      &::before {
        background-color: @primary;
      }
    }

    &.checked:hover {
      &::before {
        border-color: @primary;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    color: #c0c0c0;
    background-color: transparent;

    &::before {
      background-color: transparent;
    }
  }
}

@keyframes modal-fade-center {
  0% {
    opacity: 0;
    transform: scale(0.6) translateY(-30%);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes modal-fade-right {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes modal-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.modal-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: none;
  background-color: rgba(0, 0, 0, 0.25);
  overflow: auto;

  &.modal-opened {
    display: block;
  }

  &.modal-center {
    .modal {
      margin: 100px auto;
      animation: 0.3s ease-in-out 0s 1 alternate modal-fade-center;
    }
  }

  &.modal-right {
    .modal {
      float: right;
      height: 100%;
      animation: 0.3s ease-in-out 0s 1 alternate modal-fade-right;
    }
  }

  &.modal-left {
    .modal {
      float: left;
      height: 100%;
      animation: 0.3s ease-in-out 0s 1 alternate modal-fade-left;
    }
  }

  .modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 540px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
      0 5px 5px -3px rgba(0, 0, 0, 0.3);
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #8c8c8c;

    &:hover {
      color: #595959;
    }

    .svg-icon {
      fill: currentColor;
    }
  }

  .modal-header {
    // min-height: 40px;
    // max-height: 40px;
    flex: 0 0 40px;
    border-bottom: 1px solid #ccc;

    h3 {
      margin: 0;
      line-height: 40px;
      padding: 0 10px;
      font-weight: normal;
      font-size: 16px;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 50px;
    // min-height: 50px;
    // max-height: 50px;
    padding: 0 15px;
    // border-top: 1px solid #ccc;
    background-color: #fff;

    // padding-bottom: 30px; // 2018.12.07注释，不知道为什么有了这一条
    .btn+.btn {
      margin-left: 15px;
    }
  }

  .modal-body {
    flex: 1;
    padding: 20px 20px;

    &.scroll {
      overflow: auto;
    }

    background-color: #fff;

    h3 {
      margin-top: 0;
    }
  }
}

.form-group,
.form-actions {
  margin-bottom: 10px;
}

.form-actions {
  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  .btn {
    +.btn {
      margin-left: 10px;
    }
  }
}

.form-required {
  .form-label {
    position: relative;

    &::before {
      position: absolute;
      left: -3px;
      content: "*";
      font-weight: 600;
      color: @danger;
    }
  }
}

.form-valid-warn {
  .form-label {
    position: relative;
    z-index: 1;
    color: @danger;
    cursor: help;

    &:hover {
      .form-error {
        display: block;
      }
    }

    .form-error {
      display: none;
      position: absolute;
      left: 0;
      bottom: 100%;
      z-index: 10;
      white-space: nowrap;
      margin-bottom: 10px;
      padding: 3px 10px;
      border: 1px solid @danger;
      border-radius: 3px;
      background-color: #fff;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 6px;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-bottom-width: 0;
        border-top-color: #fff;
      }

      &::before {
        border-top-color: @danger;
      }

      &::after {
        transform: translateY(-1px);
      }
    }
  }
}

.form-label {
  padding-left: 5px;
  line-height: @fc-height;
  align-self: flex-start;

  &::after {
    content: "\ff1a";
  }

  &.empty {
    &::after {
      content: " ";
    }
  }
}

.form-readonly {
  .form-label {
    color: @text-light;

    &::after {
      display: none;
    }
  }
}

.form-tips {
  margin: 0;
  color: #BFBFBF;
  font-size: 12px;
}

.form-text {
  min-height: 32px;
  padding-left: 5px;
  text-indent: 1em;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

pre.form-text {
  margin-top: 0;
  font-family: inherit;
  white-space: pre-wrap;
}

.textarea-preview {
  margin: 0;
  white-space: pre-wrap;
  font-family: inherit;
  line-height: 1.6;
}

.form-content {
  padding-right: 5px;

  p {
    margin-bottom: 0;
  }

  .form-text {
    margin-bottom: 0;
    .word-break;
  }

  // [class^="react-datepicker"] {
  //   .form-control {
  //     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAUVBMVEUAAACKioqLi4uJiYmKioqHh4eLi4uJiYmPj4+KioqJiYmJiYmKioqKioqJiYmKioqKioqKioqJiYmKioqJiYmKioqJiYmKioqJiYmKioqKioogRGkTAAAAGnRSTlMAwH+APyBA8BAw0FDvsN9vz2Cg4F+/cJ+vkMkFZ8IAAADVSURBVHhe7ZTZCoMwEEXVmE1NrEu3+/8f2sgYI5WQUAoF6XmZO5DzMAOT4nzw7hLpIwjg0H9HmCviDlA49mInaIsMrsoLygIl0fgU+pAeXrgBOj0DC68MTM7QEsOaSjAvcNlQOPT0LKQ4PxKuVQZ2J2QShKnMQH44w1+o65rTSVGgGhduALoltHAoX+NCK0GnwV1ofD35WlXv/ysX2lCjAntf65BYq3YH8FwCd8GsdTzrWsccwW4Cg1VpQQNijRy4zHWCvsO0yQNykLrYECb9fOTFWXgBWhQh9Y9+7ZIAAAAASUVORK5CYII=);
  //     background-size: 24px 24px;
  //     background-position: 99% center;
  //     background-repeat: no-repeat;
  //     cursor: pointer;
  //   }
  // }
}

.form-horizontal {
  .form-group {
    display: flex;
    // justify-content: space-between;
    align-items: center;

    // 针对附件特殊处理
    &.form-items-start {
      align-items: start;

      .form-label {
        align-self: flex-start;
      }
    }
  }

  .form-label {
    width: 8em;
  }

  .form-content {
    flex: 1;
  }

  .form-text {
    padding-left: 0;
    text-indent: 0;
  }

  .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-control {
      flex: 1;
      border-right-width: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .btn {
      box-shadow: none;
      height: @fc-height;
      border-radius: 0;

      &:last-child {
        border-radius: 0 0.2em 0.2em 0;
      }
    }
  }

  .form-action {
    flex: 1;
    padding: 20px 0 10px;
  }
}

.form-inline {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .form-group {
    flex: 1;
    margin-bottom: 0;

    &.form-shrink {
      flex: 0;
    }

    +.form-group {
      margin-left: 10px;
    }
  }
}

.form {
  margin-bottom: 15px;
}

.form-inner {
  background-color: rgba(0, 0, 0, 0.05);

  .form-inner-title {
    font-weight: bold;
    margin: 0;
    padding: 5px 5px 10px;
    font-size: 1.2em;
  }
}

.form-split {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .form-group {
    flex: 1;

    &.form-shrink {
      flex: 0;
    }

    +.form-group {
      margin-left: 15px;
    }
  }

  .form-actions {
    flex: 1;
  }
}

.form-nodes {
  overflow: hidden;

  .node-tag {
    position: relative;
    float: left;
    display: flex;
    align-items: center;
    margin: 0 10px 0 0;
    height: 34px;
    padding-right: 20px;
    font-size: 12px;

    .org-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      border: 1px solid @primary;
      border-radius: 17px;
      background-color: #fff;

      img {
        width: 32px;
        height: 32px;
      }

      .svg-icon {
        width: 18px;
        height: 18px;
        fill: @primary;
      }
    }

    &:hover {
      background-color: #f5f5f5;
      border-radius: 17px 4px 4px 17px;

      .node-close {
        display: flex;
      }
    }
  }

  .node-close {
    position: absolute;
    top: 4px;
    right: 4px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .svg-icon {
      fill: #bdc2ca;
      width: 12px;
      height: 12px;
    }
  }

  .node-add {
    float: left;
    display: flex;
    align-items: center;
    color: @primary;
    font-size: 12px;
    cursor: pointer;

    .add-icon {
      display: flex;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      border: 1px solid @primary;
      border-radius: 16px;

      .svg-icon {
        fill: @primary;
      }
    }
  }
}

.table-container {
  max-width: 100%;
  overflow: auto;

  .table {
    max-width: 100%;
  }

  td,
  th {
    white-space: nowrap;
  }
}

.table {
  margin-bottom: 20px;
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  border-width: 1px 0;
  // font-size: 12px;
  // border-bottom: 1px solid #E9E9E9;

  thead {
    tr {
      border-bottom: 1px solid #e9e9e9;
      height: 45px;
      background-color: #fafafa;
    }
  }

  tbody {
    tr {
      // border-top: 1px solid rgba(#E9E9E9, 0.5);
      border-bottom: 1px solid #e9e9e9;
    }
  }

  th,
  td {
    text-align: left;
    padding: 8px 10px;
    // white-space: nowrap;
    .word-break;
  }

  th {
    white-space: nowrap;
    word-break: normal;
    font-weight: 600;
  }

  tbody tr {
    &:hover {
      td {
        background-color: #fafafa;
      }
    }

    &.actived {
      td {
        background-color: @primary;
        color: #fff;
      }
    }
  }

  tr.disabled {
    cursor: not-allowed;
    color: #d1d4d8;

    .checkbox-item.disabled::before {
      background: rgb(236, 232, 232);
    }

    &:hover {
      td {
        background: #fff;
      }
    }

    .file-name {
      cursor: not-allowed;
    }

    .svg-icon {
      fill: #d1d4d8;
    }
  }
}

.react-datepicker__input-container {
  display: block !important;
}

.autocomplete {
  position: relative;
  z-index: 5;

  &.autocomplete-opened {
    z-index: 6;
  }

  &.disabled {
    >.form-control {
      border-color: #eee;
      color: #ccc;
      cursor: text;
    }
  }

  .empty {
    color: @placeholder-color;
  }

  .form-control-text {
    cursor: pointer;
  }

  .autocomplete-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 240px;
    width: 100%;
    margin-top: 2px;
    max-height: 200px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: auto;

    .form-control {
      margin-bottom: 10px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    padding: 0 10px;
    height: 36px;
    line-height: 36px;
    overflow: hidden;
    white-space: nowrap;

    &.autocomplete-empty {
      color: #999;
    }

    +li {
      border-top: 1px dashed #ddd;
    }

    &:hover {
      background-color: #f3f3f3;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 12px;

  .pg-size-control {
    margin-left: 10px;
    line-height: 30px;
    font-size: 14px;

    .pg-size {
      margin-right: 5px;
      cursor: pointer;
      vertical-align: top;

      &.active {
        color: @primary;
      }
    }
  }

  form {
    margin-left: 10px;
    display: flex;
    align-items: center;

    .form-control {
      margin: 0 5px;
      width: 60px;
      height: 30px;
      text-align: center;
    }
  }

  .pg-nav {
    i {
      margin: 0 5px;
    }

    span {
      position: relative;
      display: inline-block;
      height: 30px;
      min-width: 30px;
      padding: 0 10px;
      line-height: 28px;
      text-align: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      cursor: pointer;

      &:hover {
        border-color: #f1f1f1;
        background: #f1f1f1;
      }

      +span {
        margin-left: 5px;
      }
    }

    .pg-prev {
      padding-left: 24px;

      &::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        width: 0;
        height: 0;
        overflow: hidden;
        border: 7px solid transparent;
        border-left-width: 0;
        border-right-color: #333;
      }
    }

    .pg-next {
      padding-right: 24px;

      &::after {
        content: "";
        position: absolute;
        top: 10px;
        right: 10px;
        width: 0;
        height: 0;
        overflow: hidden;
        border: 7px solid transparent;
        border-right-width: 0;
        border-left-color: #333;
      }
    }

    .current {
      background-color: @primary;
      color: #fff;
      border-color: @primary;

      &:hover {
        background-color: #00b0fe;
        border-color: #00b0fe;
      }
    }

    .disabled {
      background-color: #f0f0f0;
      color: @text-grey;
      cursor: text;

      &:hover {
        border-color: #ddd;
      }

      &.pg-prev {
        &::before {
          border-right-color: @text-grey;
        }
      }

      &.pg-next {
        &::after {
          border-left-color: @text-grey;
        }
      }
    }
  }
}

.pagination-sm {
  display: flex;
  justify-content: center;
  user-select: none;
  padding: 0;
  margin-top: 5px;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  .pg-nav {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
  }

  .btn-left,
  .btn-right {
    cursor: pointer;
  }

  .btn-left:hover,
  .btn-right:hover {
    .svg-icon {
      fill: #ec9439;
    }
  }

  .page {
    padding: 0 10px;
  }
}

.breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  font-size: 12px;
  background-color: #fff;

  .svg-icon {
    float: left;
    width: 16px;
    height: 16px;
    margin: 0 4px;
  }

  li {
    float: left;
    line-height: 16px;

    &>a {
      color: @primary;

      &:hover {
        color: @primary;
      }
    }
  }

  span {
    color: @text-light;
  }
}

.org-selector {
  .btn-group {
    margin-top: 20px;
    text-align: center;
  }

  .cp-folders {
    height: 404px;
    overflow: auto;
  }
}

.icon-search {
  position: relative;

  .form-control {
    padding-left: 36px;
  }

  .svg-icon {
    display: none;
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    // left: 10px;
    // fill: @text-light;
  }

  &::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 7px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADIklEQVR4Ae1ZA7BcUQzd2rZte1zb7aC2bdu2bds2xrVt29rtSZlmlu/dd7ftf5k5//9Vcs5Fks132GbebLNtzpw58WbMmFF62rRptaZPn94G6Am0wuPqQPEJEyZE/+tIz549OzHIdQWOgqwTcHkC3vMJ2EWi5s6dGzOoxPv16xcWRDoCbzwR9iHmAXardlDIg0BuEDhPRBTg4KxZs1JoI0+rBvLv3azobWAGUBGPs9F9wO9wkydPTojP5KR7gMcL8fupGxHPZs6cWUTHyo9xE/wqUMPlcoXy00e4Hxf7sViAL0AzK8m3cUN+MBEyePmjgfASKQK7VVI5eUqB5JwF+4zHdcz6pV2DnyFCxCsgszLySHcR4fi+CFJL8e4OF/6Pq3TeRRyb8Ypcy53YxuPgKFVVQT4GHL9gji+tWLEijMMCQyqNLWKdQ60JbVZAc7G1FS3Och1FvOJmL+9e5vCkw2LD7oYXuzDTsLMpU6bEEr1NX01VfiGL+cTwkcVKlBGXKqemSl9VxE1vdCUacUfUvOkQgJYiE487derUQkYF9GWX6bFDk1GbLQTUNHqExjFHFxwaTVT9Nkad9OK9u8ZWPYZIpXWMCmjIHDmpWgbjDlAyUZKFcBZTaspClUUWyme4tIs60FzT+Z/PYr6mOqCqEu/UUYkR5yWLucnshWrJ7wH16Tp6IWW7vmDBgihw8pw53KeSsGxdxOrT35FVnMmuMq1Z9H1gh1j9Ucq+kcH5PSbgPWUGxUdnhFikp0inCVQGKMyrIxU2BMiuYuV/DAZcAmV1TCXe0ATB5P1a5ma+dMqyognno0VAJ7AAw6tkASxEOPhpgd8PvYwd55MInZO5D8Bimr5RNpGfoSr+czIHPHFD2knn3r0I/bNR5w+SZ2liDbwSr0s8AgpDeGoa32gRwabTnU1Mp78Ay3m2cScCWKpahDweSXz9f0DgObAAvVZa7oeLoEGxLhHyaCWibvKHoFnADmA5np8EdAEK+DProXG7OxGysQuuGRCBx2tsEcEQAeI3hIj+GkKrTRRcBARMVR1DS4KgSQmwAYLi//mqbbbZ9hV25hmDvd8TGwAAAABJRU5ErkJggg==") 50% / cover no-repeat;
  }
}

.org-transport {
  width: 520px;
  height: 404px;
  display: flex;
  justify-content: space-between;

  .org-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 353px;

    .icon-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 90px;
      border: 2px solid #d1d4d9;
      border-radius: 45px;
    }

    .svg-icon {
      width: 50px;
      height: 50px;
      fill: #d1d4d9;
    }

    p {
      color: #888c9d;

      em {
        font-style: normal;
        color: #ff3568;
      }
    }
  }

  .snipper {
    display: block;
    margin: 100px auto;
    height: 40px;
  }

  .org-source {
    flex: 1;
  }

  .no-search {
    .org-select {
      margin-top: 0;
      height: 404px;
    }
  }

  .org-select {
    margin-top: 15px;
    height: 353px;
    overflow: auto;
  }

  .org-selected {
    margin-left: 20px;
    width: 220px;
    padding: 16px;
    border: 1px solid #eee;

    .selected-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 16px;
      line-height: 16px;
      font-size: 12px;

      .selected-count {
        color: @text-light;
      }

      .selected-clear {
        color: @primary;
        cursor: pointer;
      }
    }

    .org-list {
      max-height: 400px;

      li {
        cursor: default;
      }
    }
  }

  .select-list {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 354px;
    overflow: auto;
  }

  .select-item {
    margin-top: 14px;
    position: relative;
    padding-right: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #f5f5f5;
    border-radius: 17px 4px 4px 17px;

    .org-icon {
      position: relative;
      display: inline-block;
      width: 34px;
      height: 34px;
      background: #fff;
      border-radius: 17px;
      text-align: center;
      line-height: 32px;
      border: 1px solid @primary;
      font-size: 0;
      vertical-align: middle;

      .svg-icon {
        fill: @primary;
        width: 18px;
        height: 18px;
      }

      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .remove-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 0;
    cursor: pointer;

    .svg-icon {
      width: 14px;
      height: 14px;
      fill: @text-grey;
    }
  }
}

.user-list {
  margin: 15px 0 0;
  height: 353px;
  padding: 0;
  list-style: none;
  overflow: auto;

  .remove-icon {
    margin-right: 5px;
  }

  li {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 5px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .checkbox-item {
    margin-right: 10px;
  }
}

.user-view-single {
  white-space: nowrap;

  +.user-view-single {
    margin-top: 10px;
  }

  .user-code {
    color: @text-light;
  }
}

.user-view-thumb {
  .avatar {
    +.avatar {
      margin-left: 10px;
    }
  }
}

.user-view-line {
  overflow: hidden;

  .user-view-single {
    float: left;
    margin: 0 20px 10px 0;
  }
}

.empty-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 2px solid @primary;
  border-radius: 50px;

  .svg-icon {
    fill: @primary;
    width: 50px;
    height: 50px;
  }
}

.importance-new {
  display: inline-block;

  .level-0 {
    .svg-icon {
      fill: #f69623;
    }
  }

  .level-1 {
    .svg-icon {
      fill: #f56e23;
    }
  }

  .level-2 {
    .svg-icon {
      fill: #f53d23;
    }
  }
}

// 审批单紧急程度
.importance {
  display: inline-block;
  padding: 2px 10px;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;

  &.level-0 {
    background-color: @level0;

    &::before {
      content: "普通";
    }
  }

  &.level-1 {
    background-color: @level1;

    &::before {
      content: "普通";
    }
  }

  &.level-2 {
    background-color: @level2;

    &::before {
      content: "紧急";
    }
  }

  &.level-3 {
    background-color: @level3;

    &::before {
      content: "特急";
    }
  }
}

.expression-editor {
  font-family: Menlo, monospace, Consolas, "Courier New";

  textarea {
    display: block;
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ddd;
    font-size: 16px;
    font-family: inherit;

    &:focus {
      outline: none;
    }
  }

  .functions {
    margin-bottom: -1px;
    overflow: hidden;
    border: 1px solid #ddd;
    padding: 5px;
  }

  .variables {
    margin-bottom: -1px;
    padding: 5px;
    border: 1px solid #ddd;
    overflow: hidden;
  }

  .var-field {
    float: left;
    margin: 5px;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 3px;

    span {
      display: block;
      text-align: center;
    }
  }

  .field-key {
    font-weight: 600;
  }

  .field-label {
    border-top: 1px solid #ddd;
  }

  .console {
    margin-top: -1px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #1d1f21;
    color: #c5c8c6;
  }

  .error {
    color: @danger;
  }

  .operator,
  .brackets {
    color: #88beb7;
    margin: 0 1px;
  }

  .string {
    font-style: italic;
    color: #b5be63;
  }

  .number,
  .boolean {
    color: #e0935a;
  }

  .function-name {
    color: #80a2bf;
    font-weight: 600;
  }

  .express-tips {
    margin: 0 5px;
    clear: both;
    color: @text-grey;
  }
}

.valid-error {
  color: @danger;
}

.tag-controller {

  .tag,
  .btn-add,
  .add-tag-box {
    margin-bottom: 6px;
  }

  .tag {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    padding: 3px 10px;
    border-radius: 3px;
    background-color: @warn;
    color: #fff;
    font-size: 12px;
    white-space: nowrap;

    &:hover {
      .tag-close {
        display: block;
      }
    }
  }

  .tag-close {
    display: none;
    position: absolute;
    top: -5px;
    right: -5px;
    width: 14px;
    height: 14px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    border-radius: 7px;
    background-color: #fff;
    color: @text;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .tag-inline {
    display: inline-block;
    min-width: 150px;
  }

  .tag-selected {
    border: 1px solid #ddd;
    padding: 5px 10px 0;
    min-height: 35px;
    border-radius: 5px;
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;

    .tag {
      cursor: pointer;

      &.active {
        background-color: rgba(@warn, 0.5);
        cursor: text;
      }
    }
  }
}

// braft-editor
[data-title]:not(button) {
  position: relative;
  cursor: pointer;

  &.pos-top {
    &::before {
      top: auto;
      bottom: 100%;
      transform: translate(-50%, -2px);
    }

    &::after {
      top: auto;
      bottom: 100%;
      border-top-width: 4px;
      border-bottom-width: 0;
      border-top-color: rgba(59, 64, 77, 0.9);
      border-bottom-color: transparent;
    }
  }

  &::before {
    display: none;
    content: attr(data-title);
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, 6px);
    padding: 4px 8px;
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    border-radius: 3px;
    background-color: rgba(59, 64, 77, 0.9);
  }

  &::after {
    display: none;
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, 2px);
    width: 0;
    height: 0;
    overflow: hidden;
    border: 4px solid transparent;
    border-top-width: 0;
    border-bottom-color: rgba(59, 64, 77, 0.9);
  }

  &:hover {

    &::before,
    &::after {
      display: block;
    }
  }
}

[data-space] {
  &::before {
    width: 230px;
    white-space: normal;
    line-height: 20px;
  }
}
