.exception-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #fff;

  .ep-body {
    text-align: center;
  }

  img {
    width: 258px;
    height: 132px;
  }

  h3 {
    margin-top: 10px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 400;

    p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@primary-color: #22b8ff;@link-color: #22b8ff;@warning-color: #FFA431;@error-color: #FF2115;@text-color: #595959;@border-radius-base: 2px;@border-color-base: #d9d9d9;